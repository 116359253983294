import React, { useEffect, useState } from 'react';
import BottomSheet from '../../components/model/BottomSheet';
import PrimaryButton from '../buttons/PrimaryButton';
import AppDownloadNudgeSrc from '../../assets/webp-images/app-download-nudge.webp';
import { useSelector } from 'react-redux';
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';
import { Mixpanel } from '../../analytics/Mixpanel';

type AppDownloadNudgeProps = {
	screenName?: string;
};

const AppDownloadNudge: React.FC<AppDownloadNudgeProps> = ({ screenName }) => {
	const user = useSelector((state: any) => state?.auth?.user?.data);
	const [showDownloadAppNudge, setShowDownloadAppNudge] = useState(false);
	const daysToExpire = useSelector(
		(state: any) => state.plan?.userPlan?.daysToExpire
	);

	useEffect(() => {
		console.log(isDesktop);
		if (screenName === 'Payment') {
			setTimeout(() => {
				if (!isDesktop) {
					setShowDownloadAppNudge(true);
					Mixpanel.track('view_download_app_nudge', {
						event_action: 'viewed',
						event_page: 'live_classes',
						phone: user?.phone,
					});
				}
			}, 2000);
		} else if (daysToExpire > 0) {
			if (!isDesktop) {
				setShowDownloadAppNudge(true);
				Mixpanel.track('view_download_app_nudge', {
					event_action: 'viewed',
					event_page: 'live_classes',
					phone: user?.phone,
				});
			}
		}
	}, [user, daysToExpire, screenName]);

	const onOpenAppNowHandler = () => {
		window.location.href = 'https://link.seekdaily.live/download-app';
		Mixpanel.track('click_download_app_nudge', {
			event_action: 'clicked',
			event_page: 'live_classes',
			phone: user?.phone,
		});

		setTimeout(() => {
			if (isIOS) {
				window.location.href =
					'https://apps.apple.com/us/app/seek-guided-meditation-yoga/id6720720308';
			} else if (isAndroid) {
				window.location.href =
					'https://play.google.com/store/apps/details?id=com.seeker.minds.seek.app';
			}
		}, 1500);
	};

	const onNeedHelpCLickHAndler = () => {
		try {
			Mixpanel.track('click_need_help', {
				event_action: 'clicked',
				event_page: 'live_classes',
			});
			const whatsappGroupLink = 'https://wa.me/919959637700';
			window.open(whatsappGroupLink, '_blank');
		} catch (error) {
			console.error('Error:', error);
		}
	};

	return (
		<BottomSheet
			onHide={() => {}}
			customStyle={{ height: 'max-content' }}
			show={showDownloadAppNudge}
			title=''
			hideOnOverlayClick={true}
			content={
				<div
					style={{
						textAlign: 'center',
						width: '100%',
						height: '430px',
					}}>
					<img
						src={AppDownloadNudgeSrc}
						alt='App Download Nudge'
						style={{
							width: '100%',
						}}
					/>
					<p
						style={{
							color: '#1B1625',
							textAlign: 'center',
							fontFamily: 'Gordita-Medium',
							fontSize: '20px',
							marginTop: '24px',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '28px',
						}}>
						Continue on the app
					</p>

					<p
						style={{
							color: '#4A4A4A',
							textAlign: 'center',
							fontFamily: 'Gordita-Regular',
							fontSize: '16px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '24px',
						}}>
						You can now access all guided classes and courses from our mobile
						app.
					</p>
					{/* CTA Button */}
					<PrimaryButton
						onClick={onOpenAppNowHandler}
						style={{
							width: '100%',
							padding: '12px',
							borderRadius: '12px',
							fontSize: '14px',
							marginTop: '12px',
							height: '50px',
						}}>
						Open App
					</PrimaryButton>
					<p
						onClick={onNeedHelpCLickHAndler}
						style={{
							textAlign: 'center',
							fontFamily: 'Gordita-Medium',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 500,
							marginTop: '16px',
							lineHeight: '20px', // 142.857%
							textDecorationLine: 'underline',
							textDecorationColor: '#4462EF', // Match part of your gradient color or choose a custom color
							textDecorationStyle: 'solid',
							textDecorationSkipInk: 'none',
							textDecorationThickness: 'auto',
							textUnderlineOffset: 'auto',
							textUnderlinePosition: 'from-font',
							background:
								'var(--Primary-Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
							backgroundClip: 'text',
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent',
						}}>
						Need help? Contact here
					</p>
				</div>
			}
		/>
	);
};

export default AppDownloadNudge;
