import React, { useEffect, useState } from 'react';
import { Mixpanel } from '../../analytics/Mixpanel';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LandingPage from './LandingPage';
import WelcomePage from './Welcome';
import TestimonialPage from './TestimonialPage';
import ManageSignupReminders from './ManageSignupReminders';
import Congratulations from './user-experience-components/Congratulations';
import ExperienceDetailsPage from './user-experience-components/ExperienceDetailsPage';
import QuestionnairePage from './user-experience-components/QuestionnairePage';
import VideoAnimation from './user-experience-components/VideoAnimation';
import CustomerTestimonialPage from './user-experience-components/CustomerTestimonialPage';
import PrioritiesPage from './PrioritiesPage';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../services/Firebase';
import CustomerExperiencePage from './user-experience-components/CustomerExperiencePage';
import {
	OptionsOfPriorities,
	OptionsOnExperience,
	OptionsOnLanguage,
	OptionsOnPhysicalIntensity,
	ProgressSteps,
} from '../../utils/utils';
import moment from 'moment-timezone';
import { STATES as CUSTOMER_EXPERIENCE_STATE } from './user-experience-components/CustomerExperiencePage';
import { updateUserPreference } from '../../store/features/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';

import './OnBoarding.css';
import { v4 } from 'uuid';

interface Question {
	question: string;
	option: boolean | null;
}

export enum STATES {
	LANDING_PAGE,
	WELCOME_PAGE,
	PRIORITIES_PAGE,
	EXPERIENCE_DETAILS_PAGE,
	QUESTIONARE_PAGE,
	TESTIMONIAL_PAGE,
	VIDEO_ANIMATION,
	CUSTOMER_TESTIMONIAL_PAGE,
	CUSTOMER_LOCATION_PAGE,
	CUSTOMER_EXPERIENCE_PAGE,
	CUSTOMER_AGE_GROUP_PAGE,
	THANK_YOU,
	THANK_YOU_2,
	TRIAL_START_DATE,
	TRIAL_DATE_SET,
	READY_TO_SET_YOUR_GOALS,
	SET_YOUR_GOALS,
	SET_YOUR_REMINDERS,
	CONGRATULATIONS,
}

function OnBoarding() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const location = useLocation();
	const screenValue = location.state
		? location.state.screen
		: STATES.LANDING_PAGE;

	const backScreen = useSelector((state: any) => state.auth.backScreen);
	const user = location.state ? location.state.user : null;
	const [state, setState] = useState<STATES>(backScreen || screenValue);
	const [selectedExperienceIndex, setSelectedExperienceIndex] =
		useState<any>(user);
	const [isOnboardingA, setIsOnboardingA] = useState(false);

	const onboardingExperimentKey = useSelector(
		(state: any) => state.abExperiment.data.onboarding_experiment_key
	);

	const dispatch = useDispatch();
	const userPreferences = useSelector(
		(state: any) => state.auth.userPreferences
	);

	const handleUpdatePreference = (key: string, value: any) => {
		dispatch(updateUserPreference({ key, value }));
	};

	useEffect(() => {
		const now = moment();
		const currentHour = now.hour();

		const isAfter10PM = currentHour >= 22;

		const trialStartDate = isAfter10PM
			? now.add(1, 'days').format('DD MMM YYYY')
			: now.format('DD MMM YYYY');

		handleUpdatePreference('trialStartDate', trialStartDate);
	}, []);

	const [loader, setLoader] = useState(false);
	const [customerExperienceLastState, setCustomerExperienceLastState] =
		useState<CUSTOMER_EXPERIENCE_STATE>(
			isOnboardingA
				? CUSTOMER_EXPERIENCE_STATE.LANGUAGE
				: CUSTOMER_EXPERIENCE_STATE.MEDITATION_EXPERIENCE
		);
	const navigate = useNavigate();

	const onBackArrowClick = () => {
		setLoader(true);
		try {
			setLoader(false);
			setState((prevState) => {
				switch (prevState) {
					case STATES.WELCOME_PAGE:
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: 'Let us start the journey',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						return STATES.LANDING_PAGE;
					case STATES.PRIORITIES_PAGE:
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: 'What is your goal?',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						isOnboardingA && navigate(-1);
						return isOnboardingA ? STATES.PRIORITIES_PAGE : STATES.WELCOME_PAGE;

					case STATES.EXPERIENCE_DETAILS_PAGE: {
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: 'We understand your needs.',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						return STATES.PRIORITIES_PAGE;
					}

					case STATES.QUESTIONARE_PAGE: {
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: ' 1. Knowing your preferences',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						decreaseProgressBy16();
						return STATES.EXPERIENCE_DETAILS_PAGE;
					}
					case STATES.TESTIMONIAL_PAGE: {
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: 'Welcome to seek',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						return isOnboardingA
							? STATES.EXPERIENCE_DETAILS_PAGE
							: STATES.QUESTIONARE_PAGE;
					}
					case STATES.VIDEO_ANIMATION:
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: 'Multiple tracks video',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						return STATES.TESTIMONIAL_PAGE;

					case STATES.CUSTOMER_TESTIMONIAL_PAGE:
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading:
								'We work on body, breath and mind to provide you the best outcomes',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						return STATES.VIDEO_ANIMATION;

					case STATES.CUSTOMER_EXPERIENCE_PAGE:
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: '2.  Personalise your Experience',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						decreaseProgressBy5_5();
						return STATES.CUSTOMER_TESTIMONIAL_PAGE;

					case STATES.SET_YOUR_REMINDERS: {
						Mixpanel.track('click_back_button', {
							event_action: 'clicked',
							page_heading: '3. Design your Trial',
							question: 'Set your reminders',
							event_page: 'onboarding_questionnaire_info',
							onboarding_experiment_key: onboardingExperimentKey,
						});
						setProgress(66.6);
						setCustomerExperienceLastState(
							CUSTOMER_EXPERIENCE_STATE.TEXT_TESTIMONIAL_7_SLOTS
						);
						return STATES.CUSTOMER_EXPERIENCE_PAGE;
					}
					case STATES.CONGRATULATIONS:
						return STATES.SET_YOUR_REMINDERS;
					default:
						return prevState;
				}
			});
		} catch (e) {}
	};

	const onLandingPageContinueHandler = (name: string) => {
		handleUpdatePreference('name', name);

		const parsedUserPreferences = isOnboardingA && {
			...userPreferences,
			name: name,
			language: OptionsOnLanguage[userPreferences?.language].title,
		};

		isOnboardingA
			? navigate('/sign-up', { state: { parsedUserPreferences } })
			: setState(STATES.WELCOME_PAGE);

		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: 'Your Name?',
			event_page: 'onboarding_questionnaire_info',
			name: name,
			onboarding_experiment_key: onboardingExperimentKey,
		});

		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: 'Let us start the journey',
			event_page: 'onboarding_questionnaire_info',
			name: name,
			onboarding_experiment_key: onboardingExperimentKey,
		});
	};

	const onWelcomePageContinueHandler = () => {
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: 'Let us start the journey',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});

		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: 'What is your goal?',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
		setState(STATES.PRIORITIES_PAGE);
	};

	const onPrioritiesPageContinueHandler = (selectedIndexes?: any) => {
		handleUpdatePreference(
			'priorities',
			selectedIndexes?.map((index: number) => OptionsOfPriorities[index])
		);

		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: 'What is your goal?',
			event_page: 'onboarding_questionnaire_info',
			priorities: selectedIndexes?.map(
				(index: number) => OptionsOfPriorities[index]
			),
			onboarding_experiment_key: onboardingExperimentKey,
		});

		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: 'We understand your needs.',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
		setState(STATES.EXPERIENCE_DETAILS_PAGE);
	};

	const onExperienceDetailsPageContinueHandler = () => {
		increaseProgressBy16();
		isOnboardingA
			? setState(STATES.TESTIMONIAL_PAGE)
			: setState(STATES.QUESTIONARE_PAGE);

		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: 'We understand your needs.',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
	};

	const onQuestionnairePageContinueHandler = (questionState: any) => {
		setState(STATES.TESTIMONIAL_PAGE);
	};

	const onTestimonialPageContinueHandler = () => {
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: 'Welcome To Seek',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: 'Multiple tracks video',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
		setState(STATES.VIDEO_ANIMATION);
	};

	const continueFromSlotHandler = (selectedSlots: any) => {
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: '3. Design your Trial',
			question: 'Set your reminders',
			response: selectedSlots,
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});

		handleUpdatePreference('managedReminders', selectedSlots);
		setState(STATES.CONGRATULATIONS);
	};

	const continueFromCongratulationsHandler = () => {
		Mixpanel.track('click_sign_up', {
			event_action: 'clicked',
			page_heading: 'Great Job',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
		const parsedUserPreferences = {
			...userPreferences,
			userExperience:
				OptionsOnExperience[userPreferences?.userExperience].title,
			language: OptionsOnLanguage[userPreferences?.language].title,
			physical_activity:
				OptionsOnPhysicalIntensity[userPreferences?.physical_activity].title,
		};
		navigate('/sign-up', { state: { parsedUserPreferences } });
	};

	const onVideoAnimationPageContinueHandler = () => {
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading: 'Multiple tracks video',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});

		setState(STATES.CUSTOMER_TESTIMONIAL_PAGE);
	};

	const onCustomerTestimonialPageContinueHandler = () => {
		increaseProgressBy5_5();
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading:
				'We work on body, breath and mind to provide you the best outcomes',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});

		setState(STATES.CUSTOMER_EXPERIENCE_PAGE);
	};

	const thankYou2PageContinueHandler = () => {
		increaseProgressBy5_5();
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'clicked',
			page_heading:
				'40% of our community members are around your age group. And they enjoy their journey with Seek.',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: '2.  Personalise your Experience',
			event_page: "What's your experience in meditation?",
			onboarding_experiment_key: onboardingExperimentKey,
		});
		setCustomerExperienceLastState(
			CUSTOMER_EXPERIENCE_STATE.MEDITATION_EXPERIENCE
		);
		setState(STATES.CUSTOMER_EXPERIENCE_PAGE);
	};

	const onCustomerExperiencePageContinueHandler = (questionState: any) => {
		handleUpdatePreference('onboarding_questionnaire', [
			...userPreferences.onboarding_questionnaire,
			...questionState,
		]);
		setProgress(100);

		isOnboardingA
			? setState(STATES.LANDING_PAGE)
			: setState(STATES.SET_YOUR_REMINDERS);
	};

	const [progress, setProgress] = useState(
		backScreen ? 100 : ProgressSteps.SECTION_ONE
	);
	const [questionState, setQuestionState] = useState<Question[]>([]);

	const increaseProgressBy11 = () => {
		const newProgress = progress + 11.1;
		setProgress(newProgress);
	};

	const increaseProgressBy16 = () => {
		const newProgress = progress + ProgressSteps.SECTION_ONE;
		setProgress(newProgress);
	};
	const decreaseProgressBy16 = () => {
		const newProgress = progress - ProgressSteps.SECTION_ONE;
		setProgress(newProgress);
	};

	const increaseProgressBy5_5 = () => {
		const newProgress = progress + ProgressSteps.SECTION_TWO;
		setProgress(newProgress);
	};

	const decreaseProgressBy5_5 = () => {
		const newProgress = progress - ProgressSteps.SECTION_TWO;
		setProgress(newProgress);
	};

	const [videoUrl, setVideoUrl] = useState('');
	useEffect(() => {
		const fetchImage = async () => {
			try {
				const imageRef = ref(
					storage,
					'/video/Pre Sign-up Videos/ob-video-1.mp4'
				);
				const url = await getDownloadURL(imageRef);
				setVideoUrl(url);
			} catch (error) {
				console.error('Error fetching image:', error);
			}
		};
		fetchImage();
	}, []);

	useEffect(() => {
		Mixpanel.startRecording();
	}, []);

	useEffect(() => {
		if (onboardingExperimentKey) {
			if (onboardingExperimentKey === 'onboarding-A') {
				setIsOnboardingA(true);
				setCustomerExperienceLastState(
					backScreen
						? CUSTOMER_EXPERIENCE_STATE.VIDEO_TESTIMONIAL_TIME
						: CUSTOMER_EXPERIENCE_STATE.LANGUAGE
				);
				setState(backScreen || STATES.PRIORITIES_PAGE);

				Mixpanel.track('onboarding_questionnaire_info', {
					event_action: 'viewed',
					page_heading: 'What is your goal?',
					event_page: 'onboarding_questionnaire_info',
					onboarding_experiment_key: onboardingExperimentKey,
				});
			} else {
				Mixpanel.track('onboarding_questionnaire_info', {
					event_action: 'viewed',
					page_heading: 'Your Name?',
					event_page: 'onboarding_questionnaire_info',
					onboarding_experiment_key: onboardingExperimentKey,
				});
			}
		}
	}, [onboardingExperimentKey]);

	return (
		<div style={{ position: 'relative', overflow: 'hidden' }}>
			{loader && <Loader />}
			{state === STATES.LANDING_PAGE && (
				<LandingPage
					onLandingPageContinueHandler={onLandingPageContinueHandler}
				/>
			)}

			{state === STATES.WELCOME_PAGE && (
				<WelcomePage
					userPreferences={userPreferences}
					onBackArrowClick={onBackArrowClick}
					isOnboardingA={isOnboardingA}
					onWelcomePageContinueHandler={onWelcomePageContinueHandler}
				/>
			)}

			{state === STATES.PRIORITIES_PAGE && (
				<PrioritiesPage
					progress={progress}
					onBackArrowClick={onBackArrowClick}
					onPrioritiesPageContinueHandler={onPrioritiesPageContinueHandler}
				/>
			)}

			{state === STATES.EXPERIENCE_DETAILS_PAGE && (
				<ExperienceDetailsPage
					onExperienceDetailsPageContinueHandler={
						onExperienceDetailsPageContinueHandler
					}
					onBackArrowClick={onBackArrowClick}></ExperienceDetailsPage>
			)}

			{state === STATES.QUESTIONARE_PAGE && (
				<QuestionnairePage
					setQuestionState={setQuestionState}
					questionState={questionState}
					progress={progress}
					onQuestionnairePageContinueHandler={
						onQuestionnairePageContinueHandler
					}
					onBackArrowClick={onBackArrowClick}></QuestionnairePage>
			)}

			{state === STATES.TESTIMONIAL_PAGE && (
				<TestimonialPage
					selectedExperienceIndex={selectedExperienceIndex}
					onTestimonialPageContinueHandler={onTestimonialPageContinueHandler}
					onBackArrowClick={onBackArrowClick}
				/>
			)}

			{state === STATES.VIDEO_ANIMATION && (
				<>
					<VideoAnimation
						buffer={20}
						isOnboardingA={isOnboardingA}
						label={'Okay'}
						isBackArrow={true}
						videoUrl={videoUrl}
						onVideoAnimationPageContinueHandler={
							onVideoAnimationPageContinueHandler
						}
						onBackArrowClick={onBackArrowClick}
					/>
				</>
			)}

			{state === STATES.CUSTOMER_TESTIMONIAL_PAGE && (
				<CustomerTestimonialPage
					selectedExperienceIndex={selectedExperienceIndex}
					onCustomerTestimonialPageContinueHandler={
						onCustomerTestimonialPageContinueHandler
					}
					onBackArrowClick={onBackArrowClick}
				/>
			)}

			{state === STATES.CUSTOMER_EXPERIENCE_PAGE && (
				<CustomerExperiencePage
					isOnboardingA={isOnboardingA}
					setOnboardingState={setState}
					backScreen={backScreen}
					currentState={customerExperienceLastState}
					increaseProgressBy5_5={increaseProgressBy5_5}
					decreaseProgressBy5_5={decreaseProgressBy5_5}
					name={
						userPreferences?.name?.split(' ')?.[0]?.charAt(0)?.toUpperCase() +
						userPreferences?.name?.split(' ')?.[0]?.slice(1)
					}
					progress={progress}
					onBackArrowClick={onBackArrowClick}
					onCustomerExperiencePageContinueHandler={
						onCustomerExperiencePageContinueHandler
					}
				/>
			)}

			{state === STATES.SET_YOUR_REMINDERS && (
				<ManageSignupReminders
					progress={progress}
					continueFromSlotHandler={continueFromSlotHandler}
					onBackArrowClick={onBackArrowClick}
				/>
			)}

			{state === STATES.CONGRATULATIONS && (
				<Congratulations
					continueFromCongratulationsHandler={
						continueFromCongratulationsHandler
					}
					onBackArrowClick={onBackArrowClick}
				/>
			)}
			<ToastContainer />
		</div>
	);
}

export default OnBoarding;
