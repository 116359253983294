import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as SeekLogo } from '../../assets/seek-logo.svg';
import './TestimonialPage.css';
import Button from '../../components/button/Button';
import { ReactComponent as BackArrow } from '../../assets/left_arrow_black.svg';
import { ReactComponent as TestimonialHeading } from '../../assets/new-icons/testimonial-heading.svg';
import TextAnimation from './user-experience-components/TextAnimation';
import { Mixpanel } from '../../analytics/Mixpanel';
import { useSelector } from 'react-redux';

interface TestimonialPageProps {
	onTestimonialPageContinueHandler: () => void;
	onBackArrowClick: () => void;
	selectedExperienceIndex: number;
}

const TestimonialPage: React.FC<TestimonialPageProps> = ({
	onTestimonialPageContinueHandler,
	onBackArrowClick,
	selectedExperienceIndex,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const onboardingExperimentKey = useSelector(
		(state: any) => state.abExperiment.data.onboarding_experiment_key
	);

	const [disabled, setDisabled] = useState(true);
	const [startAnimationTwo, setStartAnimationTwo] = useState(false);

	const onAnimationComplete = (index: number) => {
		if (index === 1) {
			setStartAnimationTwo(true);
		} else if (index === 2) {
			setDisabled(false);
		}
	};

	useEffect(() => {
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: 'Welcome to Seek',
			onboarding_experiment_key: onboardingExperimentKey,
		});
	}, []);

	return (
		<Container className={'testimonial-page-container'}>
			<div className={'testimonial-header-container'}>
				<BackArrow onClick={onBackArrowClick} width={48} height={48} />
				<div />
				{/*<span className={'ques-header-title'}>1.  Knowing your preferences</span>*/}
				<div />
			</div>

			<div className={'testimonial-body-container'}>
				<TextAnimation
					style={{
						color: '#1B1625',
						textAlign: 'center',
						fontFamily: 'Galaxie-Copernicus-Book',
						fontSize: '24px',
						fontStyle: 'normal',
						width: '100%',
						fontWeight: 400,
						lineHeight: '32px',
					}}
					startAnimation={true}
					index={1}
					title={'Welcome to Seek'}
					onAnimationComplete={onAnimationComplete}
				/>
				{disabled && (
					<TextAnimation
						style={{
							color: '#1B1625',
							fontFamily: 'Gordita-Medium',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: 500,
							zIndex: 5,
							lineHeight: '28px',
						}}
						startAnimation={startAnimationTwo}
						index={2}
						title={
							" India's first ever wellness platform, where we provide you what suits you."
						}
						onAnimationComplete={onAnimationComplete}
					/>
				)}
				{!disabled && <TestimonialHeading />}
			</div>
			<Button
				disabled={disabled}
				isSticky={true}
				style={{
					alignSelf: 'center',
					marginBottom: '24px',
					background: disabled
						? 'var(--Inactive-Color, #D6D6D6)'
						: 'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
				}}
				onClick={onTestimonialPageContinueHandler}
				label={'Show Me How'}></Button>
		</Container>
	);
};

export default TestimonialPage;
