import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import './CourseCover.css';
import ratingsIcon from './../../../assets/star.svg';
import liveIcon from './../../../assets/new-icons/course-live-dark-icon.svg';
import selfPacedIcon from './../../../assets/new-icons/course-paced-dark-icon.svg';
import durationIcon from './../../../assets/new-icons/course-duration-dark-icon.svg';
import languageIcon from './../../../assets/new-icons/course-language-dark-icon.svg';
import calenderIcon from './../../../assets/new-icons/course-calender-dark-icon.svg';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../services/Firebase';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { updateEventsStatus } from '../../../store/features/EventsSlices';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import Shimmer from '../../../components-v2/shimmer/Shimmer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment-timezone';
import PrimaryButton from '../../../components-v2/buttons/PrimaryButton';

interface CourseCoverProps {
	course: any;
	onClick: () => void;
}

const CourseCover: React.FC<CourseCoverProps> = ({ course, onClick }) => {
	const [coverUrl, setCoverUrl] = useState<string>('');
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();

	const view_upcoming_courses = useSelector(
		(state: any) =>
			state?.events?.eventsLog?.[`view_upcoming_courses_${course?.courseId}`]
	);

	const formattedStartDate = useMemo(
		() =>
			moment(new Date(course.batch[0].start_date._seconds * 1000)).format(
				'DD MMM YY'
			),
		[course]
	);

	const formattedEndDate = useMemo(
		() =>
			moment(new Date(course.batch[0].end_date._seconds * 1000)).format(
				'DD MMM YY'
			),
		[course]
	);

	const logViewEvent = useCallback(() => {
		if (!view_upcoming_courses) {
			Mixpanel.track('view_upcoming_courses', {
				event_action: 'viewed',
				event_page: 'courses',
				course_title: course?.title,
				course_id: course?.id,
			});
			dispatch(
				updateEventsStatus({
					key: `view_upcoming_courses_${course?.courseId}`,
					value: true,
				})
			);
		}
	}, [view_upcoming_courses, dispatch, course]);
	const cardRef = useIntersectionObserver(logViewEvent, { threshold: 0.5 });

	useEffect(() => {
		setLoading(true);
		(async () => {
			try {
				const imageRef = ref(storage, course.thumbnail);
				const url = await getDownloadURL(imageRef);
				setCoverUrl(url);
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		})();
	}, [course.batch]);

	if (loading)
		return (
			<>
				<Shimmer isDark style={{ height: '350px' }} />
			</>
		);

	return (
		<div ref={cardRef} className='course-cover'>
			{coverUrl && (
				<div
					style={{
						aspectRatio: '343/215',
						overflow: 'hidden',
						position: 'relative',
					}}>
					<LazyLoadImage
						className={'course-cover-image img-fluid'}
						src={coverUrl}
						alt=''
						effect='blur'
						onLoad={() => (
							<Shimmer isDark classname='course-cover-image-placeholder' />
						)}
					/>
					<div className='course-cover-image-tag'>
						<p>
							{(course.batch[0].start_date._seconds - Date.now() / 1000) /
								(60 * 60 * 24) >
							7
								? 'Upcoming'
								: `Closes in ${Math.round(
										(course.batch[0].start_date._seconds - Date.now() / 1000) /
											(60 * 60 * 24)
								  )} days`}
						</p>
					</div>
				</div>
			)}
			<div className='course-cover-info'>
				<div className='course-cover-info-header'>
					<p className='course-cover-info-header-title'>{course.title}</p>
					<div className='course-cover-info-header-rating'>
						<p className='course-cover-info-header-rating-number'>
							{course?.course_ratings?.rating}
						</p>
						<img src={ratingsIcon} alt='' />
						<p className='course-cover-info-header-rating-count'>{`(${course?.course_ratings?.count})`}</p>
					</div>
				</div>
				<div className='course-cover-info-date'>
					<img src={calenderIcon} alt='' />
					<p>
						{formattedStartDate} - {formattedEndDate}
					</p>
				</div>
				<div className='course-cover-info-details'>
					<div className='course-cover-info-details-item'>
						<img
							style={{
								margin:
									course.courseFormat === 'Self_Paced' ? '-4px 4px 0 4px' : '',
							}}
							src={
								course.courseFormat === 'Self_Paced' ? selfPacedIcon : liveIcon
							}
							alt=''
						/>
						<p>
							{course.courseFormat === 'Self_Paced'
								? 'Self paced'
								: course.batch[0].class_time}
						</p>
					</div>
					<div className='course-cover-info-details-divider'></div>
					<div className='course-cover-info-details-item'>
						<img src={durationIcon} alt='' />
						<p>{course.duration}</p>
					</div>
					<div className='course-cover-info-details-divider'></div>
					<div className='course-cover-info-details-item'>
						<img src={languageIcon} alt='' />
						<p>{course.language}</p>
					</div>
				</div>
				<div className='course-cover-info-bio'>
					<p>{course.course_bio ?? ''}</p>
				</div>
				<div className='course-cover-info-footer'>
					<div className='course-cover-info-footer-price'>
						<p className='course-cover-info-footer-price-offer'>
							₹
							{course.batch[0]?.offers_valid
								? course.batch[0].course_offer_price
								: course.batch[0].price}
						</p>
						{course.batch[0]?.offers_valid && (
							<p className='course-cover-info-footer-price-actual'>
								₹{course.batch[0].price}
							</p>
						)}
					</div>
					<div>
						<PrimaryButton onClick={onClick}>
							<p style={{ marginBottom: 0 }}>View Details</p>
						</PrimaryButton>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CourseCover;
