import React, { ChangeEvent, useEffect, useState } from 'react';
import './SingUpForm.css';
import '../../styles.css';
import PhoneInput from '../../components/phoneInput/PhoneInput';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../utils/colors';
import { Mixpanel } from '../../analytics/Mixpanel';
import { events, events_action, events_page } from '../../analytics/Events';
import Loader from '../../components/loader/Loader';
import { RecaptchaVerifier } from 'firebase/auth';
import signup_banner from '../../assets/webp-images/sign_up_baneer.webp';
import PrimaryButton from '../../components-v2/buttons/PrimaryButton';
import { isMobileDevice } from '../../utils/utils';
import { ReactComponent as BackArrow } from '../../assets/left_arrow_black.svg';
import { ReactComponent as SeekLogo } from '../../assets/seek-logo.svg';
import { ReactComponent as Close } from '../../assets/close-cta.svg';
import CheckboxWithTitle from './CheckBoxWithTitle';
import BottomSheet from '../../components/model/BottomSheet';

interface SignUpFormProps {
	onFormSubmitHandler: (formData: any) => void;
	onBackArrowClick: () => void;
	setError: any;
	user: any;
	name: String;
}

interface WindowWithRecaptchaVerifier extends Window {
	recaptchaVerifier?: RecaptchaVerifier;
	confirmationResult?: any;
}

declare let window: WindowWithRecaptchaVerifier;
const SignUpForm: React.FC<SignUpFormProps> = ({
	onFormSubmitHandler,
	user,
	setError,
	onBackArrowClick,
	name,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [phoneNumber, setPhoneNumber] = useState<string>(user?.phone || '');
	const [loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const userString = window.localStorage.getItem('user');
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
	const [isChecked, setIsChecked] = useState(true);
	const [isInfoClicked, setIsInfoClicked] = useState(false);

	useEffect(() => {
		if (!isKeyboardOpen && isMobileDevice()) {
			window.scrollTo(0, 0);
		}
	}, [isKeyboardOpen]);

	useEffect(() => {
		if (!userString) {
			try {
				Mixpanel.track(events.view_sign_up_page, {
					event_page: events_page.sign_up,
					event_action: events_action.viewed,
				});
				Mixpanel.track(events.view_opt_in_checkbox, {
					event_page: events_page.sign_up,
					event_action: events_action.viewed,
				});
			} catch (e) {}
		}
		setLoader(true);
		if (userString) {
			navigate('/home');
			setLoader(false);
		} else {
			setLoader(false);
		}
	}, [userString]);
	const customInputStyle: React.CSSProperties = {
		// width: '300px',
		marginTop: '10px',
	};

	const handlePhoneChange = (
		value: string,
		country: string,
		e: ChangeEvent<HTMLInputElement>,
		formattedValue: string
	): void => {
		setPhoneNumber(value);
		const indianPhoneNumberPattern = /^91[6-9]\d{9}$/;
		if (indianPhoneNumberPattern.test(value)) {
			Mixpanel.track('sign_up_phone_number_filled', {
				event_page: 'sign_up',
				event_action: events_action.viewed,
				phone_number: value,
			});
		} else {
			console.log('invalid phone number', value);
		}
	};

	const customDropdownStyle: React.CSSProperties = {
		// Add your custom dropdown styles here if needed
	};

	const onSubmitHandler = async () => {
		if (
			phoneNumber === '' ||
			(phoneNumber.slice(0, 2) === '91' && phoneNumber.length !== 12)
		) {
			setError('Please enter a valid mobile number');
			return;
		}
		setLoader(true);
		try {
			Mixpanel.track(events.click_proceed_sign_up, {
				event_page: events_page.sign_up,
				event_action: events_action.clicked,
				checkbox_status: isChecked,
				response: {
					phone: phoneNumber,
					source: 'WEB',
					notificationPreference: isChecked,
				},
			});
			onFormSubmitHandler({
				phone: phoneNumber,
				source: 'WEB',
				notificationPreference: isChecked,
			});
		} catch (e: any) {
			setLoader(false);
		}
	};

	return (
		<div className={'signup-form-container'}>
			{loader && <Loader />}
			<div
				className={'header-container'}
				style={{
					width: '100%',
					marginTop: '12px',
					justifyContent: 'space-around',
				}}>
				<BackArrow width={48} height={48} onClick={onBackArrowClick} />
				<SeekLogo
					style={{
						alignSelf: 'center',
						width: '100%',
						// marginLeft: '12px',
						alignItems: 'center',
					}}
					width={108}
					height={44}
				/>
				<div></div>
			</div>
			<img
				loading='lazy'
				src={signup_banner}
				alt=''
				className={'img-fluid'}
				style={{
					width: '200px',
					alignSelf: 'center',
					height: '180px',
					objectFit: 'cover',
					marginTop: '36px',
				}}
			/>
			<div
				style={{
					alignSelf: 'center',
					marginTop: '32px',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<span className={'signup-title'}>The Last Step!</span>
			</div>
			<span className={'signup-form-title'} style={{ marginTop: '16px' }}>
				WhatsApp Number
			</span>
			<PhoneInput
				onFocusHandle={() => setIsKeyboardOpen(true)}
				onBlurHandle={() => setIsKeyboardOpen(false)}
				defaultCountry='in'
				onPhoneChange={handlePhoneChange}
				phoneNumber={phoneNumber}
				inputStyle={customInputStyle}
				dropdownStyle={customDropdownStyle}
			/>
			<PrimaryButton
				isSticky={true}
				style={{
					backgroundColor: COLORS.PRIMARY,
					alignSelf: 'center',
					marginBottom: '24px',
					width: '327px',
					height: '50px',
				}}
				textStyle={{ fontSize: '16px' }}
				onClick={onSubmitHandler}>
				{'Continue'}
			</PrimaryButton>

			<CheckboxWithTitle
				onInfoClick={() => {
					Mixpanel.track(events.click_info_icon_opt_in, {
						event_page: events_page.sign_up,
						event_action: events_action.clicked,
					});
					setIsInfoClicked((prevState) => !prevState);
				}}
				title='Receive reminders on WhatsApp'
				checked={isChecked}
				onChange={(checked) => setIsChecked(checked)}
			/>

			<BottomSheet
				customStyle={{ height: '35%' }}
				show={isInfoClicked}
				showHeader={false}
				onHide={setIsInfoClicked.bind(null, false)}
				title={''}
				content={
					<div
						style={{
							width: '100%',
							maxWidth: '400px',
							overflowY: 'auto',
							overflowX: 'hidden',
							alignSelf: 'start',
						}}>
						<div
							style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
							<span
								style={{
									width: '100%',
									maxWidth: '400px',
									overflowY: 'auto',
									overflowX: 'hidden',
									alignSelf: 'start',
									display: 'flex',
									// marginTop: '16px',
									position: 'relative',
									color: '#2A2A2A',
									fontFamily: 'Gordita-Medium',
									fontSize: '20px',
									fontStyle: 'normal',
									fontWeight: '400',
									lineHeight: '28px',
								}}>
								WhatsApp Reminders
							</span>
							<Close
								width={24}
								height={24}
								onClick={setIsInfoClicked.bind(null, false)}
							/>
						</div>
						<span
							style={{
								display: 'flex',
								marginTop: '20px',
								width: '100%',
								color: '#2A2A2A',
								fontFamily: 'Gordita-Regular',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: '400',
							}}>
							By agreeing to the Terms, you agree to receive
							promotional,transactional,or other communications from Seek. You
							can opt out from such communications by changing your notification
							preferences as available in your profile section of Seek.
						</span>
					</div>
				}
			/>
		</div>
	);
};

export default SignUpForm;
