import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as BackArrow } from '../../../assets/left_arrow_black.svg';
import './VideoAnimation.css';
import Button from '../../../components/button/Button';
import { ReactComponent as MuteIcon } from '../../../assets/mute.svg';
import { ReactComponent as UnmuteIcon } from '../../../assets/unmute.svg';
import ReactPlayer from 'react-player';
import { Mixpanel } from '../../../analytics/Mixpanel';
import Loader from '../../../components/loader/Loader';
import { useSelector } from 'react-redux';

interface TestimonialPageProps {
	onVideoAnimationPageContinueHandler: () => void;
	onBackArrowClick: () => void;
	videoUrl: string;
	label: string;
	isBackArrow: boolean;
	buffer: number;
	isOnboardingA: boolean;
}

const VideoAnimation: React.FC<TestimonialPageProps> = ({
	buffer,
	label,
	isBackArrow,
	onVideoAnimationPageContinueHandler,
	onBackArrowClick,
	videoUrl,
	isOnboardingA,
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [isVideoEnded, setIsVideoEnded] = useState(false);
	const [isVideoPlaying, setIsVideoPlaying] = useState(false);

	const [muted, setMuted] = useState(true);

	const onboardingExperimentKey = useSelector(
		(state: any) => state.abExperiment.data.onboarding_experiment_key
	);

	const handlePlay = () => {
		setIsVideoPlaying(true);
		setTimeout(
			() => {
				setIsVideoEnded(true);
			},
			isOnboardingA ? 0 : 5000
		);
	};

	const handleMuteClick = () => {
		if (muted) {
			Mixpanel.track('click_mute', {
				event_action: 'clicked',
				event_page: 'pre_sign_up_videos',
				onboarding_experiment_key: onboardingExperimentKey,
			});
		}
		setMuted(!muted);
	};

	return (
		<Container className={'video-animation-page-container'}>
			<div className={'video-animation-header-container'}>
				{isBackArrow ? (
					<BackArrow onClick={onBackArrowClick} width={48} height={48} />
				) : (
					<div style={{ marginTop: '64px' }}></div>
				)}
				<div className='video-animation-volume'>
					{muted ? (
						<MuteIcon onClick={handleMuteClick} />
					) : (
						<UnmuteIcon onClick={handleMuteClick} />
					)}
				</div>
			</div>
			<div className={'video-animation-body-container'}>
				{!isVideoPlaying && (
					<div
						style={{
							position: 'absolute',
							zIndex: 0,
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
						}}>
						<Loader grey />
					</div>
				)}
				{
					<ReactPlayer
						controls={false}
						playing={true}
						autoPlay={true}
						muted={muted}
						loop={true}
						onPlay={handlePlay}
						playsinline={true}
						fileconfig={{ attributes: { autoPlay: true } }}
						url={videoUrl}
						width={'100%'}
						height={472}
						style={{
							display: 'flex',
							opacity: isVideoPlaying ? '1' : '0.01',
							width: '100%',
							maxWidth: '327px',
							maxHeight: '472px',
							height: 'auto',
							background: 'white',
							backgroundColor: 'transparent',
							alignSelf: 'center',
							margin: '-16px auto',
						}}
					/>
				}

				<Button
					disabled={!isVideoEnded}
					isSticky={true}
					style={{
						alignSelf: 'center',
						marginBottom: '24px',
						background: !isVideoEnded
							? 'var(--Inactive-Color, #D6D6D6)'
							: 'var(--Primary_Gradient, linear-gradient(106deg, #4462EF 32.65%, #1B3DD8 105.18%))',
					}}
					onClick={onVideoAnimationPageContinueHandler}
					label={label}></Button>
			</div>
		</Container>
	);
};

export default VideoAnimation;
