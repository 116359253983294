import React, { useEffect } from 'react';
import { ReactComponent as BackArrow } from '../../../assets/left_arrow_black.svg';
import { ReactComponent as GreatJob } from '../../../assets/great-job.svg';
import Button from '../../../components/button/Button';
import './Congratulations.css';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { useSelector } from 'react-redux';

interface CongratulationsPropType {
	continueFromCongratulationsHandler: () => void;
	onBackArrowClick: () => void;
}

const Congratulations: React.FC<CongratulationsPropType> = ({
	continueFromCongratulationsHandler,
	onBackArrowClick,
}) => {
	const onboardingExperimentKey = useSelector(
		(state: any) => state.abExperiment.data.onboarding_experiment_key
	);
	useEffect(() => {
		window.scrollTo(0, 0);
		Mixpanel.track('onboarding_questionnaire_info', {
			event_action: 'viewed',
			page_heading: 'Great Job',
			event_page: 'onboarding_questionnaire_info',
			onboarding_experiment_key: onboardingExperimentKey,
		});
	}, []);

	return (
		<div className={'congrats-page-container'}>
			<div className={'congrats-header-container '}>
				<BackArrow onClick={onBackArrowClick} width={48} height={48} />
				<div />
				<div />
			</div>
			<div className={'congrats-body-container'}>
				<GreatJob
					style={{ marginLeft: '8px', width: '100%', alignSelf: 'center' }}
					height={57}
				/>
				<span className={'congrats-title'}>{`Your reminders are set!`}</span>
				<span className={'congrats-title'}>
					{`You can change the reminders anytime from`}
				</span>
				<span className={'congrats-title'}>{`the 'profile' section.`}</span>
			</div>
			<Button
				isSticky={true}
				style={{ alignSelf: 'center', marginBottom: '24px' }}
				onClick={continueFromCongratulationsHandler}
				label={'Continue'}></Button>
		</div>
	);
};

export default Congratulations;
