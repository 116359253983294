import React, { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import './CheckoutPage.css';
import '../../../styles.css';
import moment from 'moment-timezone';
import { isEmpty, STREAMING_PLAN_RECURRENCE_TYPE } from '../../../utils/utils';
import dcImg from './../../../assets/webp-images/dc-checkout.png';
import sleepIcon from './../../../assets/webp-images/sleep-addon-icon.png';
import sleepAddon from './../../../assets/webp-images/sleep-added.png';
import reviewIcon from './../../../assets/star.svg';
import upArrow from './../../../assets/up.svg';
import useAuthenticatedUsers from '../../../hooks/useAuthenticatedUsers';
import { useSelector } from 'react-redux';
import { Mixpanel } from '../../../analytics/Mixpanel';
import BackButton from '../../../components-v2/buttons/BackButton';

interface CheckoutPageProps {
	total_karma: number;
	course: any;
	onBackArrowClick: () => void;
	selectedPlanVariant: any;
	isCommunityMember: boolean;
	isUserLogin: boolean;
	addonAdded: boolean;
	setAddonOpen: (value: boolean) => void;
	payNowClickHandler: any;
	setAddonAdded: (value: boolean) => void;
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({
	isUserLogin,
	selectedPlanVariant,
	course,
	addonAdded,
	setAddonOpen,
	setAddonAdded,
	payNowClickHandler,
	onBackArrowClick,
}) => {
	const currentDate = moment().format('DD MMM YYYY');
	const endDate = moment(currentDate);
	const [addedModal, setAddedModal] = useState(false);
	const [finalPrice, setFinalPrice] = useState(selectedPlanVariant.total_price);

	const userData = useAuthenticatedUsers(true);
	const user = useSelector((state: any) => state?.auth?.user?.data);

	if (selectedPlanVariant.type === STREAMING_PLAN_RECURRENCE_TYPE.MONTHLY) {
		endDate.add(1, 'months');
	} else if (
		selectedPlanVariant.type === STREAMING_PLAN_RECURRENCE_TYPE.QUARTERLY
	) {
		endDate.add(3, 'months');
		// endDate.add(1, 'months'); // add 1 month ,offer extension
	} else if (
		selectedPlanVariant.type === STREAMING_PLAN_RECURRENCE_TYPE.YEARLY
	) {
		endDate.add(1, 'year');
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		Mixpanel.track('view_daily_class_payment_details_page', {
			event_action: 'viewed',
			addon_added: addonAdded,
			event_page: 'daily_class_payment_details_page',
		});
	}, []);

	useEffect(() => {
		if (addonAdded) {
			setFinalPrice(selectedPlanVariant.total_price + course.price / 2 - 1);
		} else {
			setFinalPrice(selectedPlanVariant.total_price);
		}
	}, [addonAdded]);

	return (
		<Container className={'checkout-container'}>
			<div style={{ width: '100%', maxWidth: '375px', marginTop: '24px' }}>
				<BackButton onClick={onBackArrowClick} />
			</div>
			{addonAdded && (
				<div
					style={{
						width: '100%',
						maxWidth: '375px',
						marginTop: '16px',
						padding: '8px 16px',
						backgroundColor: '#F4F2FF',
						borderRadius: '12px',
						fontSize: '14px',
						fontFamily: 'Gordita-Medium',
					}}>
					💰 You just saved ₹{course.price / 2 + 1} on the course
				</div>
			)}
			<span className={'checkout-details'}>What you own</span>
			<div
				style={{
					width: '100%',
					maxWidth: '375px',
					borderRadius: '8px',
					marginTop: '16px',
					marginBottom: '8px',
					display: 'flex',
					gap: '8px',
				}}>
				<div style={{ width: '80px' }}>
					<img
						style={{ width: '100%', borderRadius: '8px' }}
						src={dcImg}
						alt=''
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}>
					<p
						style={{
							fontFamily: 'Gordita-Medium',
							textAlign: 'left',
							width: '100%',
							fontSize: '16px',
							maxWidth: '375px',
							marginBottom: '4px',
						}}>
						Seek Daily Class
					</p>
					<p
						style={{
							fontFamily: 'Gordita-Regular',
							textAlign: 'left',
							width: '100%',
							fontSize: '14px',
							maxWidth: '375px',
							marginBottom: '12px',
						}}>
						{`${selectedPlanVariant?.name.toLowerCase()} `} plan
					</p>
					<p
						style={{
							fontSize: '11px',
							fontFamily: 'Gordita-Medium',
							color: '#4a4a4a',
							marginBottom: '0',
						}}>
						30 min guided classes - 7 time slots
					</p>
				</div>
			</div>
			{!!course && (
				<>
					<div
						style={{
							width: '100%',
							maxWidth: '375px',
							borderRadius: '12px',
							marginTop: '12px',
							display: 'flex',
						}}>
						<div style={{ maxWidth: '80px', marginRight: '8px' }}>
							<img style={{ width: '100%' }} src={sleepIcon} alt='' />
						</div>
						<div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
							<p
								style={{
									fontFamily: 'Gordita-Medium',
									textAlign: 'left',
									width: '100%',
									fontSize: '16px',
									maxWidth: '375px',
									marginBottom: 0,
								}}>
								{course.title} Course
							</p>
							<p
								style={{
									fontFamily: 'Gordita-Regular',
									fontSize: '12px',
									maxWidth: '375px',
									marginBottom: 0,
									color: '#4a4a4a',
								}}>
								Designed for deep sleep
							</p>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginTop: 'auto',
								}}>
								<p
									style={{
										fontFamily: 'Gordita-Medium',
										fontSize: '12px',
										marginBottom: 0,
									}}>
									{course?.course_ratings?.rating}
								</p>
								<img
									style={{ width: '12px', margin: '0 4px' }}
									src={reviewIcon}
									alt=''
								/>
								<p
									style={{
										fontFamily: 'Gordita-Regular',
										fontSize: '12px',
										marginBottom: 0,
										color: '#4a4a4a',
									}}>
									({course?.course_ratings?.count})
								</p>
							</div>
						</div>
						<div
							style={{
								width: '75px',
								marginLeft: '12px',
								display: ' flex',
								flexDirection: 'column',
							}}>
							<p
								style={{
									fontFamily: 'Gordita-Medium',
									fontSize: '14px',
									marginBottom: 0,
									textAlign: 'right',
								}}>
								₹{course?.price / 2 - 1}
							</p>
							<p
								style={{
									fontFamily: 'Gordita-Regular',
									fontSize: '12px',
									marginBottom: 0,
									textDecoration: 'line-through',
									color: '#4a4a4a',
									textAlign: 'right',
								}}>
								₹{course?.price}
							</p>
							<p
								onClick={() => {
									Mixpanel.track('click_addon_learn_more', {
										event_action: 'clicked',
										event_page: 'daily_class_payment_details_page',
									});
									setAddonOpen(true);
								}}
								style={{
									backgroundColor: '#fff',
									fontFamily: 'Gordita-Medium',
									color: '#a4a4a4',
									fontSize: '12px',
									textAlign: 'right',
									width: '100%',
									maxWidth: '375px',
									cursor: 'pointer',
									marginBottom: '0',
									marginTop: 'auto',
									textDecoration: 'underline',
								}}>
								Learn more
							</p>
						</div>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							maxWidth: '375px',
							width: '100%',
							marginTop: '12px',
						}}>
						<div
							onClick={() => {
								Mixpanel.track('click_add_remove_addon', {
									event_action: 'clicked',
									event_page: 'daily_class_payment_details_page',
									action: addonAdded ? 'remove' : 'add',
								});
								if (!addonAdded) {
									setAddedModal(true);
									setTimeout(() => {
										setAddedModal(false);
									}, 3000);
								}
								setAddonAdded(!addonAdded);
							}}
							style={{
								border: '1px solid #4462EF',
								backgroundColor: '#fff',
								padding: '6px 12px',
								fontFamily: 'Gordita-Medium',
								color: '#4462EF',
								fontSize: '12px',
								textAlign: 'center',
								borderRadius: '12px',
								marginBottom: '24px',
								cursor: 'pointer',
							}}>
							{addonAdded ? '× Added' : '+ Add'}
						</div>
					</div>
					<div
						style={{
							height: '1px',
							backgroundColor: '#e4e4e4',
							width: '100%',
							maxWidth: '375px',
							marginBottom: '8px',
						}}></div>
				</>
			)}
			<span className={'checkout-details'}>Price Details</span>
			<div
				style={{
					borderRadius: '12px',
					marginTop: '16px',
					width: '100%',
					maxWidth: '375px',
				}}>
				<div className={'checkout-plan-details-container'}>
					<span className={'checkout-plan-start-date'}>
						{selectedPlanVariant?.name.toLowerCase() + ' Seek Daily Class'}
					</span>
					<span
						className={
							'checkout-plan-start-date-value'
						}>{`₹${selectedPlanVariant?.total_price}`}</span>
				</div>
				{addonAdded && (
					<>
						<div
							style={{ marginTop: '12px' }}
							className={'checkout-plan-details-container'}>
							<span className={'checkout-plan-start-date'}>
								{'3-day Sleep Better Course'}
							</span>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span className={'checkout-plan-start-date-value'}>{`₹${
									course.price / 2 - 1
								}`}</span>
								<span
									style={{
										fontSize: '10px',
										color: '#a4a4a4',
										textDecoration: 'line-through',
									}}
									className={
										'checkout-plan-start-date-value'
									}>{`₹${course.price}`}</span>
							</div>
						</div>
					</>
				)}
				<div
					style={{
						borderBottom: '1px dashed #e4e4e4',
						margin: '12px 0',
					}}></div>
				<div className={'checkout-plan-details-container'}>
					<span
						style={{
							fontFamily: 'Gordita-Bold',
							fontSize: '14px',
						}}>
						{'Total amount'}
					</span>
					<span
						className={
							'checkout-plan-start-date-value'
						}>{`₹${finalPrice}`}</span>
				</div>
			</div>

			<div style={{ marginBottom: '132px' }}></div>
			<div
				className='plan-fixed-bottom-container'
				style={{ paddingBottom: '12px' }}>
				<span className='plan-fixed-bottom-price'>{'₹' + finalPrice}</span>
				<button
					className='plan-fixed-bottom-button'
					onClick={() => {
						if (userData != null || !isEmpty(user)) {
							Mixpanel.track('click_pay_now', {
								event_action: 'clicked',
								plan_name: selectedPlanVariant?.name,
								course_name: course ? course.title : null,
								addon_added: addonAdded,
								total_cart_amount: finalPrice,
								userId: user?.uid,
								event_page: 'daily_class_payment_details_page',
							});
						} else {
							Mixpanel.track('click_login_and_pay_checkout_page', {
								event_action: 'clicked',
								plan_name: selectedPlanVariant?.name,
								addon_added: addonAdded,
								course_name: course ? course.title : null,
								total_cart_amount: finalPrice,
								event_page: 'daily_class_payment_details_page',
							});
						}
						payNowClickHandler(
							finalPrice,
							selectedPlanVariant?.name,
							addonAdded
						);
					}}>
					{isUserLogin ? 'Pay Now' : 'Login & Pay'}
				</button>
			</div>
			<Modal centered show={addedModal}>
				<div>
					<img
						style={{ width: '50%', marginLeft: '25%' }}
						src={sleepAddon}
						alt=''
					/>
					<p
						style={{
							textAlign: 'center',
							fontFamily: 'Gordita-Medium',
							fontSize: '20px',
							margin: 0,
						}}>
						🎉Congratulations!
					</p>
					<p
						style={{
							textAlign: 'center',
							fontFamily: 'Gordita-Regular',
							fontSize: '14px',
							margin: '0 16px 16px',
						}}>
						Your journey towards better sleep and mental health starts here.
					</p>
				</div>
			</Modal>
		</Container>
	);
};

export default CheckoutPage;
