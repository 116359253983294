import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	addUsers,
	deleteUserPreferences,
} from '../../store/features/AuthSlice';
import '../home/components/AccountCreated.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { firebaseLogout, getUserByUserId } from '../../services/Firebase';
import { useNavigate } from 'react-router-dom';
import './css/ProfileConatiner.css';
import { COLORS } from '../../utils/colors';
import seek_logo_url from '../../assets/seek-logo.svg';
import WeekDateRange from '../../components/weekDateRange/WeekDateRange';
import {
	CancellationPolicy,
	capitalizeFirstLetter,
	ContactUs,
	isEmpty,
	PrivacyPolicy,
	TermsAndCondition,
} from '../../utils/utils';
import { Mixpanel } from '../../analytics/Mixpanel';
import dayjs from 'dayjs';

import { ReactComponent as UpgradePlanBg } from '../../assets/new-icons/upgrade-plan-bg.svg';
import { ReactComponent as UpgradePlanBgExpired } from '../../assets/new-icons/upgrade-plan-bg-expired.svg';

import { deletePlan } from '../../store/features/PlanSlice';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import Header from '../../components-v2/header/Header';
import Logout from '../../components-v2/buttons/Logout';
import PlanStatusBanner from '../../components-v2/upgrade-plan/PlanStatusBanner';
import SettingsListContainer from './SettingsListContainer';
import FullScreenPopup from '../../components/metaInfo/FullScreenPopup';
import { updateCurrentTab } from '../../store/features/BottomNavSlice';
import { updateEventsStatus } from '../../store/features/EventsSlices';
import useFetchStreamingPlan from '../../hooks/useFetchStreamingPlan';
import { events, events_action, events_page } from '../../analytics/Events';
import Shimmer from '../../components-v2/shimmer/Shimmer';
import AppDownloadNudge from '../../components-v2/app-download-nudge/AppDownloadNudge';

const ProfileContainer = memo(() => {
	const userData = useAuthenticatedUsers();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector((state: any) => state?.auth?.user?.data);

	const force_refresh_on_plan_purchase = useSelector(
		(state: any) => state?.auth?.user?.force_refresh_on_plan_purchase
	);
	const [metaInfo, setMetaInfo] = useState<any>('');
	const view_top_card_profile = useSelector(
		(state: any) => state?.events?.eventsLog?.view_top_card_profile
	);
	useFetchStreamingPlan(userData, user, force_refresh_on_plan_purchase);
	useEffect(() => {
		if (isEmpty(user) && userData?.uid) {
			getUserByUserId(userData?.uid).then((userLoginResult) => {
				dispatch(addUsers(userLoginResult?.userData));
				Mixpanel.identify(userData?.uid);
			});
		}
	}, [userData]);

	useEffect(() => {
		if (!view_top_card_profile && user?.firstName) {
			Mixpanel.track('view_top_card_profile', {
				event_action: 'viewed',
				name:
					capitalizeFirstLetter(user?.firstName) +
					' ' +
					capitalizeFirstLetter(user?.lastName),
				// email: user?.email?.toLowerCase(),
				phone: user?.phone || user?.phoneNumber,
				event_page: 'profile',
			});
			dispatch(
				updateEventsStatus({ key: 'view_top_card_profile', value: true })
			);
		}
	}, [view_top_card_profile, user]);

	const weekDateRangeRef = useRef<any>(null);
	const activeStreamingPlan = useSelector(
		(state: any) => state.plan?.userPlan?.activeStreamingPlan
	);
	const daysToExpire = useSelector(
		(state: any) => state.plan?.userPlan?.daysToExpire
	);

	console.log('daysToExpire', daysToExpire);
	console.log('activeStreamingPlan', activeStreamingPlan);

	const logOut = async () => {
		const isLoggedOut = await firebaseLogout();
		window.localStorage.removeItem('user');
		window.localStorage.removeItem('JoinedWhatAppsCommunity');
		Mixpanel.resetIdentity();
		// TODO clean redux here->userpreferences and current selectedtab
		dispatch(deleteUserPreferences());
		dispatch(deletePlan());
		dispatch(updateCurrentTab(1));
		Mixpanel.track('click_logout', {
			event_action: 'clicked',
			event_page: 'profile',
		});
		if (isLoggedOut) {
			navigate('/login');
		}
	};

	const onKarmaPointsClickHandler = () => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'home_screen',
		});

		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	};

	const onClickUpgradePlanBannerHandler = () => {
		Mixpanel.track('click_upgrade_now_plan_banner', {
			event_action: 'clicked',
			plan_type: activeStreamingPlan?.active_plan_name,
			ending_date: dayjs(
				new Date(activeStreamingPlan?.endDate?._seconds * 1000)
			),
			event_page: 'profile',
		});
		setLoading(true);
		navigate('/plan', { state: { user: user }, replace: false });
	};

	const manageRemindersHandler = () => {
		navigate('/manage-reminders', { state: { user: user }, replace: false });
	};

	const manageNotificationRemindersHandler = () => {
		Mixpanel.track(events.click_notification_preference, {
			event_page: events_page.profile,
			event_action: events_action.clicked,
		});
		navigate('/manage-notification', { state: { user: user }, replace: false });
	};

	const onClickMenu = (menu: string) => {
		switch (menu) {
			case 'Privacy Policy': {
				Mixpanel.track('click_privacy_policy', {
					event_action: 'clicked',
					event_page: 'profile',
				});
				setMetaInfo(PrivacyPolicy);
				break;
			}
			case 'Terms and Conditions': {
				Mixpanel.track('click_terms_and_conditions', {
					event_action: 'clicked',
					event_page: 'profile',
				});
				setMetaInfo(TermsAndCondition);
				break;
			}
			case 'Contact us': {
				Mixpanel.track('click_contact_us', {
					event_action: 'clicked',
					event_page: 'profile',
				});
				setMetaInfo(ContactUs);
				break;
			}
			case 'Cancellations Policy': {
				Mixpanel.track('click_cancellations_policy', {
					event_action: 'clicked',
					event_page: 'profile',
				});
				setMetaInfo(CancellationPolicy);
				break;
			}
			case 'My Referrals': {
				Mixpanel.track('click_refer_a_friend', {
					event_action: 'clicked',
					event_page: 'profile',
				});
				navigate('/referralHistory', {
					state: { userId: user?.uid },
					replace: false,
				});
				break;
			}
		}
	};

	return (
		<div className={'profile-container'}>
			<Header
				onClick={onKarmaPointsClickHandler}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
			/>
			<div className={'profile-component-wrapper'}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						// paddingLeft: '16px',
						maxWidth: '400px',
						width: '90%',
					}}>
					<span
						style={{
							color: '#1B1625',
							fontFamily: 'Galaxie-Copernicus-Book',
							fontSize: '24px',
							width: '100%',
							alignSelf: 'start',
							textAlign: 'start',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '32px',
						}}>
						{capitalizeFirstLetter(user?.name) ||
							capitalizeFirstLetter(user?.firstName + user?.lastName) ||
							capitalizeFirstLetter(userData?.name) ||
							'Hi, Seeker'}
					</span>

					<div
						style={{
							color: '#4A4A4A',
							fontFamily: 'Gordita-Regular',
							fontSize: '16px',
							width: '100%',
							fontStyle: 'normal',
							fontWeight: 500,
							lineHeight: '24px',
							marginTop: '4px',
						}}>
						{userData?.email?.toLowerCase() || user?.email?.toLowerCase()}
						{(userData?.email?.toLowerCase() || user?.email?.toLowerCase()) && (
							<svg
								style={{ marginLeft: '4px', marginRight: '4px' }}
								xmlns='http://www.w3.org/2000/svg'
								width='2'
								height='16'
								viewBox='0 0 2 16'
								fill='none'>
								<path
									d='M1.68 0.495972V15.504H0V0.495972H1.68Z'
									fill='#E4E4E4'
								/>
							</svg>
						)}
						{`${userData?.phone || user?.phone || user?.phoneNumber}`}
					</div>
				</div>
			</div>

			{daysToExpire !== null ? (
				<PlanStatusBanner
					onCtaClick={onClickUpgradePlanBannerHandler}
					Icon={daysToExpire > 0 ? UpgradePlanBg : UpgradePlanBgExpired}
					customStyle={{
						background:
							daysToExpire > 0
								? COLORS.Secondary_Color_Light_Background
								: '#F4F4F4',
					}}
					ctaText={'Upgrade Now'}
				/>
			) : (
				<Shimmer></Shimmer>
			)}

			<WeekDateRange userId={userData?.uid} ref={weekDateRangeRef} />

			<SettingsListContainer
				onClickWhatApp={manageRemindersHandler}
				onClickMenu={onClickMenu}
				manageNotificationRemindersHandler={manageNotificationRemindersHandler}
			/>
			<div
				className={'profile-component-wrapper'}
				style={{
					display: 'flex',
					flexDirection: 'row',
					background: 'white',
					justifyContent: 'space-around',
					maxWidth: '400px',
					marginTop: '16px',
				}}>
				<span
					onClick={onClickMenu.bind(null, 'Terms and Conditions')}
					className={'profile-setting-tnc'}>
					{'Terms & Conditions'}
				</span>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='3'
					height='15'
					viewBox='0 0 3 15'
					fill='none'>
					<path
						d='M1.49991 0C1.96383 0 2.33991 0.376081 2.33991 0.84V14.168C2.33991 14.6319 1.96383 15.008 1.49991 15.008C1.03599 15.008 0.659912 14.6319 0.659912 14.168V0.840001C0.659912 0.376081 1.03599 0 1.49991 0Z'
						fill='#A4A4A4'
					/>
				</svg>
				<span
					// onClick={onClickMenu.bind(null, 'Privacy Policy')}
					onClick={() => navigate('/privacy-policy')}
					className={'profile-setting-tnc'}>
					{'Privacy Policy'}
				</span>
			</div>
			<Logout onLogoutCLickHandler={logOut} />
			<AppDownloadNudge />
			{metaInfo && (
				<FullScreenPopup
					title={metaInfo?.title}
					subtitle={metaInfo?.subtitle}
					body={metaInfo?.body}
					phone={metaInfo?.phone}
					email={metaInfo?.email}
					onClose={setMetaInfo.bind(null, '')}
				/>
			)}
		</div>
	);
});

export default ProfileContainer;
