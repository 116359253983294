import React, { useRef, useState } from 'react';
import liveIcon from '../../../assets/new-icons/course-live-dark-icon.svg';
import durationIcon from '../../../assets/new-icons/course-duration-dark-icon.svg';
import timeIcon from '../../../assets/new-icons/course-time-dark-icon.svg';
import dropdownIcon from '../../../assets/new-icons/dropdown-arrow.svg';

type CourseCurriculumDayProps = {
	day: {
		week: string | null;
		availability: string | null;
		live_class_time: string | null;
		title: string | null;
		evening_practice: string | null;
		journaling: string | null;
		module: string | null;
		task_description: string | null;
		tag: string | null;
		recorded_class_time: string | null;
		morning_practice: string | null;
		day_bio: string | null;
		assessments: string | null;
	};
	light?: boolean;
};

const CourseCurriculumDay: React.FC<CourseCurriculumDayProps> = ({
	day,
	light,
}) => {
	if (day.tag === 'Rest_Day') {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '4px',
					marginTop: '8px',
				}}>
				<div style={{ background: '#EB5757', height: '1px', flex: 1 }}></div>
				<p
					style={{
						color: '#EB5757',
						marginBottom: 0,
						fontFamily: 'Gordita-Medium',
						fontSize: '12px',
					}}>
					Rest Day
				</p>
				<div style={{ background: '#EB5757', height: '1px', flex: 1 }}></div>
			</div>
		);
	}
	if (day.tag === 'One_One_Interaction') {
		return (
			<div className='course-curriculum-week-day-1on1'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p
							className={`course-curriculum-week-day-module-text-title ${
								light ? 'light' : ''
							}`}>
							{day.module}
						</p>
						<p
							className={`course-curriculum-week-day-module-text-subtitle ${
								light ? 'light' : ''
							}`}>
							{day.task_description}
						</p>
					</div>
					<div
						style={{ backgroundColor: '#fff' }}
						className='course-curriculum-week-day-module-tag'>
						1:1 interaction
					</div>
				</div>
				{!!day.day_bio && (
					<p
						className={`course-curriculum-week-day-bio ${
							light ? 'light' : ''
						}`}>
						{day.day_bio}
					</p>
				)}
			</div>
		);
	}
	if (day.tag === 'Live_Class') {
		return (
			<div className='course-curriculum-week-day-live'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p
							className={`course-curriculum-week-day-module-text-title ${
								light ? 'light' : ''
							}`}>
							{day.module}
						</p>
						<p
							className={`course-curriculum-week-day-module-text-subtitle ${
								light ? 'light' : ''
							}`}>
							{day.task_description}
						</p>
					</div>
					<div
						style={{
							backgroundColor: light ? '#F5EFFF' : '#A0E4BD',
							border: light ? '1px solid #9B7EBD' : '',
						}}
						className='course-curriculum-week-day-module-tag'>
						Live class
					</div>
				</div>
				<div className='course-curriculum-week-day-details'>
					<div>
						<img src={liveIcon} alt='' />
						<p
							className={`course-curriculum-week-day-details-text ${
								light ? 'light' : ''
							}`}>
							{day.live_class_time}
						</p>
					</div>
					{!light && (
						<div
							style={{
								height: '16px',
								width: '1px',
								backgroundColor: '#4a4a4a',
							}}></div>
					)}
					<div>
						<img src={timeIcon} alt='' />
						<p
							className={`course-curriculum-week-day-details-text ${
								light ? 'light' : ''
							}`}>
							{day.availability}
						</p>
					</div>
				</div>
				{!!day.day_bio && (
					<p
						className={`course-curriculum-week-day-bio ${
							light ? 'light' : ''
						}`}>
						{day.day_bio}
					</p>
				)}
			</div>
		);
	}
	if (day.tag === 'New_Class') {
		return (
			<div className='course-curriculum-week-day-new'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p
							className={`course-curriculum-week-day-module-text-title ${
								light ? 'light' : ''
							}`}>
							{day.module}
						</p>
						<p
							className={`course-curriculum-week-day-module-text-subtitle ${
								light ? 'light' : ''
							}`}>
							{day.task_description}
						</p>
					</div>
					<div>
						<div
							style={{
								backgroundColor: '#F9F0FE',
								border: light ? '1px solid #B8ACF6' : '',
							}}
							className='course-curriculum-week-day-module-tag'>
							New class
						</div>
					</div>
				</div>
				<div className='course-curriculum-week-day-details'>
					<div>
						<img src={durationIcon} alt='' />
						<p
							className={`course-curriculum-week-day-details-text ${
								light ? 'light' : ''
							}`}>
							{day.recorded_class_time}
						</p>
					</div>
					{!light && (
						<div
							style={{
								height: '16px',
								width: '1px',
								backgroundColor: '#4a4a4a',
							}}></div>
					)}
					<div>
						<img src={timeIcon} alt='' />
						<p
							className={`course-curriculum-week-day-details-text ${
								light ? 'light' : ''
							}`}>
							{day.availability}
						</p>
					</div>
				</div>
				{!!day.day_bio && (
					<p
						className={`course-curriculum-week-day-bio ${
							light ? 'light' : ''
						}`}>
						{day.day_bio}
					</p>
				)}
			</div>
		);
	}
	if (day.tag === 'Self_Practices') {
		return (
			<div className='course-curriculum-week-day-new'>
				<div className='course-curriculum-week-day-module'>
					<div className='course-curriculum-week-day-module-text'>
						<p
							className={`course-curriculum-week-day-module-text-title ${
								light ? 'light' : ''
							}`}>
							{day.module}
						</p>
						<p
							className={`course-curriculum-week-day-module-text-subtitle ${
								light ? 'light' : ''
							}`}>
							{day.task_description}
						</p>
					</div>
					<div
						style={{
							backgroundColor: '#FFF3E4',
							border: light ? '1px solid #FAA53D' : '',
						}}
						className='course-curriculum-week-day-module-tag'>
						Self practices
					</div>
				</div>
				<div className='course-curriculum-week-day-info'>
					{!!day.morning_practice && (
						<div
							className={`course-curriculum-week-day-info-item ${
								light ? 'light' : ''
							}`}>
							<p
								className={`course-curriculum-week-day-info-item-title ${
									light ? 'light' : ''
								}`}>
								Morning practices
							</p>
							<p
								className={`course-curriculum-week-day-info-item-subtitle ${
									light ? 'light' : ''
								}`}>
								{day.morning_practice}
							</p>
						</div>
					)}
					{!!day.evening_practice && (
						<div
							className={`course-curriculum-week-day-info-item ${
								light ? 'light' : ''
							}`}>
							<p
								className={`course-curriculum-week-day-info-item-title ${
									light ? 'light' : ''
								}`}>
								Evening practices
							</p>
							<p
								className={`course-curriculum-week-day-info-item-subtitle ${
									light ? 'light' : ''
								}`}>
								{day.evening_practice}
							</p>
						</div>
					)}
					{!!day.assessments && (
						<div
							className={`course-curriculum-week-day-info-item ${
								light ? 'light' : ''
							}`}>
							<p
								className={`course-curriculum-week-day-info-item-title ${
									light ? 'light' : ''
								}`}>
								Assessment
							</p>
							<p
								className={`course-curriculum-week-day-info-item-subtitle ${
									light ? 'light' : ''
								}`}>
								{day.assessments}
							</p>
						</div>
					)}
					{!!day.journaling && (
						<div
							className={`course-curriculum-week-day-info-item ${
								light ? 'light' : ''
							}`}>
							<p
								className={`course-curriculum-week-day-info-item-title ${
									light ? 'light' : ''
								}`}>
								Journaling
							</p>
							<p
								className={`course-curriculum-week-day-info-item-subtitle ${
									light ? 'light' : ''
								}`}>
								{day.journaling}
							</p>
						</div>
					)}
				</div>
				{!!day.day_bio && (
					<p
						className={`course-curriculum-week-day-bio ${
							light ? 'light' : ''
						}`}>
						{day.day_bio}
					</p>
				)}
			</div>
		);
	}
	return <></>;
};

type Props = {
	curriculum: {
		[key: string]: {
			week: string | null;
			availability: string | null;
			live_class_time: string | null;
			title: string | null;
			evening_practice: string | null;
			journaling: string | null;
			module: string | null;
			task_description: string | null;
			tag: string | null;
			recorded_class_time: string | null;
			morning_practice: string | null;
			day_bio: string | null;
			assessments: string | null;
		}[];
	};
	light?: boolean;
};

function CourseCurriculum({ curriculum, light }: Props) {
	const [currentIndex, setCurrentIndex] = useState(0);

	const curriculumRef = useRef<HTMLDivElement>(null);

	return (
		<div>
			{Object.values(curriculum).map((week, index) => {
				const onWeekClick = () => {
					setCurrentIndex(index);
					if (currentIndex === index) {
						setCurrentIndex(-1);
					}
				};
				return (
					<div
						ref={curriculumRef}
						style={{
							height: currentIndex === index ? 'max-content' : '52px',
							width: light ? '100%' : '344px',
							background: light
								? currentIndex !== index
									? '#ffffff'
									: '#FFF3E4'
								: 'linear-gradient(138deg, #242751 23.7%, #232323 57.18%)',
							border: light
								? currentIndex !== index
									? '0.5px solid #a4a4a4'
									: '0.5px solid #ffffff'
								: '0.5px solid #4a4a4a',
						}}
						key={index}
						className={`course-curriculum-week ${light ? 'light' : ''}`}>
						<div
							onClick={onWeekClick}
							className='course-curriculum-week-header'>
							<p
								style={{ color: light ? '#1B1625' : 'white' }}
								className='course-curriculum-week-title'>
								{Object.keys(curriculum)[index]}
							</p>
							<img
								style={{
									transform: currentIndex === index ? '' : 'rotate(180deg)',
								}}
								src={dropdownIcon}
								alt=''
							/>
						</div>
						<div>
							{week.map((day, index: number) => {
								return (
									<>
										<p
											style={{ color: light ? '#1B1625' : '#b8acf6' }}
											className='course-curriculum-week-day-title'>
											{day.title}
										</p>
										<CourseCurriculumDay day={day} light={light} />
										{index !== week.length - 1 && (
											<div
												style={{
													border: light
														? '1px dashed #d6d6d6'
														: '1px dashed #4a4a4a',
												}}
												className='course-curriculum-week-day-divider'></div>
										)}
									</>
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default CourseCurriculum;
