// TODO new upcoming code

import React, { useEffect, useState } from 'react';
import useAuthenticatedUsers from '../../hooks/useAuthenticatedUsers';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { ReactComponent as TimeIcon } from '../../assets/course-watch.svg';

import { ReactComponent as SessionInfoOne } from '../../assets/session_info_icon_one.svg';
import { ReactComponent as SessionInfoTwo } from '../../assets/session_info_icon_two.svg';
import { ReactComponent as SessionInfoThree } from '../../assets/session_info_icon_three.svg';

import { getDownloadURL, ref } from 'firebase/storage';
import {
	allotKarmaPoints,
	getUpcomingCourses,
	getYoutubeLiveClassLink,
	getZoomLiveClassLink,
	markLiveStreamingAttendance,
	storage,
} from '../../services/Firebase';
import './UpcomingSessions.css';
import { capitalizeFirstLetter } from '../../utils/utils';
import { Mixpanel } from '../../analytics/Mixpanel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { COLORS } from '../../utils/colors';
import { ReactComponent as ZoomDate } from '../../assets/new-icons/zoom-date.svg';
import { ReactComponent as ZoomDuration } from '../../assets/new-icons/zoom-time.svg';
import { ReactComponent as LowEffort } from '../../assets/new-icons/easy-difficulty-level.svg';
import { ReactComponent as HighEffort } from '../../assets/new-icons/high-difficulty-level.svg';
import { ReactComponent as MediumEffort } from '../../assets/new-icons/medium-difficulty-level.svg';
import { ReactComponent as WatchIcon } from '../../assets/new-icons/live-clock.svg';

import { ReactComponent as LanguageIcon } from '../../assets/new-icons/language-icon.svg';
import Header from '../../components-v2/header/Header';
import seek_logo_url from '../../assets/seek-logo.svg';
import BackButton from '../../components-v2/buttons/BackButton';
import KnowYourInstructor from '../instructor/KnowYourInstructor';
import PrimaryButton from '../../components-v2/buttons/PrimaryButton';
import BestOutOfItIconWithName from '../../components-v2/rows/BestOutOfItIconWithName';
import isEmpty from 'lodash/isEmpty';
import {
	setForceRefreshOnCoursePurchase,
	setUpcomingCourses,
} from '../../store/features/UpcomingClassesSlice';
import { useDispatch, useSelector } from 'react-redux';
import CourseNudge from '../../components-v2/nudges/CourseNudge';

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	section1: { start: 0, end: 500, section_heading: 'session_info' },
	section2: { start: 500, end: 700, section_heading: 'know_your_instructor' },
	// Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };

interface Props {
	name: string;
	index: number;
}

const SessionInfoIconWithName: React.FC<Props> = ({ name, index }) => {
	if (index > 2) return null;
	if (!name) {
		return null;
	}
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				height: 'auto',
				flexDirection: 'row',
				alignItems: 'center',
				borderRadius: '12px',
				flex: 4,
				marginTop: index === 0 ? '18px' : '16px',
				padding: '8px',
				border: '1px solid #E4E4E4', // Assuming purple gradient fallback color is #534DE0
				background: '#fff',
				backdropFilter: 'blur(8px)', // Adjust the blur strength as needed
			}}>
			<div style={{ flex: 1, display: 'flex' }}>
				{index === 0 && (
					<SessionInfoThree
						width={64}
						height={64}
						style={{ marginRight: '8px' }}
					/>
				)}
				{index === 1 && (
					<SessionInfoTwo
						width={64}
						height={64}
						style={{ marginRight: '8px' }}
					/>
				)}
				{index === 2 && (
					<SessionInfoOne
						width={64}
						height={64}
						style={{ marginRight: '8px' }}
					/>
				)}
			</div>
			<div
				style={{
					// Container for the icon with fixed size
					marginRight: '8px',
					flexDirection: 'column',
					display: 'flex',
					flex: 3,
					alignItems: 'flex-start',
				}}>
				{index === 0 && (
					<span className={'upcoming-session-highlights-title'}>
						{'Movement/Kriyas'}
					</span>
				)}
				{index === 1 && (
					<span className={'upcoming-session-highlights-title'}>
						{'Breathworks'}
					</span>
				)}
				{index === 2 && (
					<span className={'upcoming-session-highlights-title'}>
						{'Meditation'}
					</span>
				)}
				<span className={'upcoming-session-highlights-subtitle'}>{name}</span>
			</div>
		</div>
	);
};

const UpcomingSessions = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const userData = useAuthenticatedUsers(false);

	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const [ctaTitle, setCtaTitle] = useState('loading...');
	const [isZoomRecording, setIsZoomRecording] = useState(false);

	const calendarItem = location?.state?.calendarItem;
	const zoomClassTime = location?.state?.classTime;
	const zoomClassDate = location?.state?.classDate;

	const user = location?.state?.user;
	const [isWithinStreamingWindow, setIsWithinStreamingWindow] = useState(
		location?.state?.isWithinStreamingWindow || false
	);
	const formattedDate = moment(
		new Date(
			calendarItem?.streaming_date._seconds * 1000 +
				calendarItem?.streaming_date._nanoseconds / 1000000
		)
	).format('DD MMM YY');
	const targetDate = new Date(formattedDate);

	const upcomingCourses = useSelector(
		(state: any) => state.upcomingClasses?.upcomingCourse?.data
	); // Assuming upcomingClasses state is stored in Redux
	const force_refresh_on_course_purchase = useSelector(
		(state: any) => state.upcomingClasses?.force_refresh_on_course_purchase
	); // Assuming upcomingClasses state is stored in Redux

	const currentDate = new Date();
	const [bestOutOfIt, setBestOutOfIt] = useState([
		'Use headphones',
		'Find a quiet space where you are not disturbed',
		'Use yoga mat',
		'Keep a glass of water',
	]);
	const [specialInstruction, setSpecialInstruction] = useState<any>(null);

	useEffect(() => {
		const modifiedValue =
			calendarItem?.how_to_get_the_best_of_this_class?.list_item_one;
		if (modifiedValue) {
			// setBestOutOfIt(prevArray => [...prevArray, modifiedValue]);
			setSpecialInstruction(modifiedValue);
		}
	}, [calendarItem?.how_to_get_the_best_of_this_class?.list_item_one]);

	useEffect(() => {
		if (isEmpty(upcomingCourses) || force_refresh_on_course_purchase) {
			getUpcomingCourses(user.uid || userData?.uid)
				.then((result: any) => {
					console.log('Api call on courseContainer in Redux', result.data);
					dispatch(setUpcomingCourses(result.data));
					dispatch(setForceRefreshOnCoursePurchase(false));
				})
				.catch((err: any) => {
					console.log('getUpcomingClasses err', err);
				});
		}
	}, [upcomingCourses, userData, force_refresh_on_course_purchase]);

	targetDate.setHours(0, 0, 0, 0);
	currentDate.setHours(0, 0, 0, 0);

	const [imageUrl, setImageUrl] = useState('');

	const [youtubeLink, setYoutubeLink] = useState(
		'https://www.youtube.com/channel/UC4E3wAchb9R-0kj5RZ-wbnQ/live'
	);

	const [readMore, setReadMore] = useState(false);
	const [nextUpcomingSlot, setNextUpcomingSlot] = useState<any>([]);
	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
	});

	useEffect(() => {
		if (isWithinStreamingWindow) {
			if (calendarItem.classType === 'YOUTUBE') {
				setCtaTitle('Join on YouTube');
			} else {
				if (
					new Date().getTime() >
					calendarItem?.streaming_date._seconds * 1000 + 90 * 60 * 1000
				) {
					setIsZoomRecording(true);
					setCtaTitle('View Recording');
				} else {
					setCtaTitle('Join on Zoom');
				}
			}
		} else {
			if (calendarItem.classType === 'YOUTUBE') {
				nextUpcomingSlot?.slot &&
					setCtaTitle(`Next Session at ${nextUpcomingSlot?.slot}`);
			} else {
				setCtaTitle(`Join on Zoom at ${zoomClassTime}`);
			}
		}
	}, [calendarItem, isWithinStreamingWindow, nextUpcomingSlot, zoomClassTime]);

	const markMixPanelEvent = (position: number) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				Mixpanel.track('view_class_page_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					class_name: calendarItem?.title,
					instructor_name: calendarItem?.instructor,
					event_page: 'class_page',
					class_type: calendarItem?.classType,
				});
				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		const handleScroll = () => {
			markMixPanelEvent(window.scrollY);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents]);

	useEffect(() => {
		if (calendarItem?.title) {
			Mixpanel.track('view_class_page', {
				event_action: 'viewed',
				class_name: calendarItem?.title,
				instructor_name: calendarItem?.instructor,
				intensity: calendarItem?.effort,
				class_tag: calendarItem?.classTag,
				language: calendarItem?.language || 'English',
				event_page: 'class_page',
				class_type: calendarItem?.classType,
			});
		}
	}, [calendarItem]);

	useEffect(() => {
		(async () => {
			try {
				const imageRef = ref(storage, calendarItem?.thumbnail);
				const imageUrl = await getDownloadURL(imageRef);
				setImageUrl(imageUrl);
			} catch (error) {
				console.error('Error fetching image:', error);
			}
		})();
	}, [calendarItem]);

	useEffect(() => {
		(async () => {
			const fetchAndUpdateLink = async () => {
				try {
					const youtubeLinkResponse = await getYoutubeLiveClassLink();
					setNextUpcomingSlot(
						youtubeLinkResponse?.data.find((slot: any) => slot.isUpcomingClass)
					);
					if (youtubeLinkResponse?.within_streaming_window) {
						setIsWithinStreamingWindow(true);
						setYoutubeLink(
							youtubeLinkResponse?.live_streaming_class_link ||
								'https://www.youtube.com/channel/UC4E3wAchb9R-0kj5RZ-wbnQ/live'
						);
						setCtaTitle('Join on YouTube');
					} else {
						nextUpcomingSlot?.slot &&
							setCtaTitle(`Next Session at ${nextUpcomingSlot?.slot}`);

						setIsWithinStreamingWindow(false);
					}
				} catch (error) {
					console.error('Error fetching YouTube link:', error);
				}
			};

			const fetchZoomAndUpdate = async () => {
				try {
					const currentTime = new Date().getTime();
					const streamingTime = new Date(
						calendarItem.streaming_date._seconds * 1000
					).getTime();

					if (currentTime > streamingTime - 10 * 60 * 1000) {
						setIsWithinStreamingWindow(true);
						const youtube_title = calendarItem?.youtube_title;
						const response = await getZoomLiveClassLink(youtube_title);
						console.log(youtube_title, response);

						setYoutubeLink(response.classLinks[0]);

						if (
							new Date().getTime() >
							calendarItem?.streaming_date._seconds * 1000 + 90 * 60 * 1000
						) {
							setCtaTitle('View Recording');
						} else {
							setCtaTitle('Join on Zoom');
						}
					} else {
						setCtaTitle(`Join on Zoom at ${zoomClassTime}`);
						setIsWithinStreamingWindow(false);
					}
				} catch (error) {
					console.error('Error fetching Zoom link:', error);
				}
			};

			// Fetch and update the link initially
			calendarItem?.classType === 'ZOOM'
				? await fetchZoomAndUpdate()
				: await fetchAndUpdateLink();

			// Set up periodic fetching after an interval (e.g., every 3 minutes)
			const intervalInMinutes = 1;
			const intervalId = setInterval(() => {
				calendarItem?.classType === 'ZOOM'
					? fetchZoomAndUpdate()
					: fetchAndUpdateLink();
			}, intervalInMinutes * 60 * 1000);
			// Clean up the interval on component unmount
			return () => clearInterval(intervalId);
		})();
	}, [calendarItem, nextUpcomingSlot?.slot, zoomClassTime]);

	const onJoinLiveSessionHandler = async () => {
		if (targetDate > currentDate) {
			toast.warning('Please join the class on the scheduled date.', {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
				hideProgressBar: true,
			});

			Mixpanel.track('click_join_live_class', {
				event_action: 'clicked',
				active_status: isWithinStreamingWindow,
				class_name: calendarItem?.title,
				instructor_name: calendarItem?.instructor,
				event_page: 'class_page',
				class_tag: calendarItem?.classTag,
				class_type: calendarItem?.classType,
			});
			return;
		}

		try {
			Promise.all([
				markLiveStreamingAttendance(userData?.uid),
				allotKarmaPoints(userData?.uid),
			]).then((result: any) => {
				console.log('Attendance API response else:', result[0]);
				console.log('Karma Points API response else:', result[1]);
			});

			Mixpanel.track('click_join_live_class', {
				event_action: 'clicked',
				active_status: isWithinStreamingWindow,
				class_name: calendarItem?.title,
				instructor_name: calendarItem?.instructor,
				event_page: 'class_page',
				class_tag: calendarItem?.classTag,
				class_type: calendarItem?.classType,
				event_key: isZoomRecording ? 'recording' : '',
			});

			calendarItem?.classType === 'ZOOM'
				? window.open(youtubeLink || calendarItem?.class_link, '_blank')
				: window.open(calendarItem?.class_link || youtubeLink, '_blank');
			// setRedirectingToYoutubeClass(false);
		} catch (e: any) {
			console.error('Error:', e.message);
		}
	};

	const readMoreHandler = () => {
		Mixpanel.track('read_more', {
			event_action: 'clicked',
			class_name: calendarItem?.title,
			instructor_name: calendarItem?.instructor,
			event_page: 'class_page',
		});
		setReadMore((prevState) => !prevState);
	};

	const parsedClassDescription = calendarItem?.description?.split('/');
	const onKarmaPointsClickHandler = () => {
		Mixpanel.track('click_karma_points_badge', {
			event_action: 'clicked',
			context_user_id: user?.uid,
			total_karma_points: user?.total_karma || 0,
			event_page: 'class_page',
		});
		navigate('/karma', {
			state: { email: user?.email, total_karma: user?.total_karma },
			replace: false,
		});
	};

	const onClick = (courseId: string, courseName: string) => {
		Mixpanel.track('click_course_nudge', {
			event_action: 'clicked',
			event_page: 'class_page',
			class_name: calendarItem?.title,
			course_name: courseName,
			instructor_name: calendarItem?.instructor,
		});
		navigate(`/upcoming-course/${courseId}`, {
			state: {
				user,
			},
		});
		// TODO takes the course to he course landing page with course id;
	};

	return (
		<div className='upcoming-session-container'>
			<Header
				onClick={onKarmaPointsClickHandler}
				logoUrl={seek_logo_url}
				karma_points={user?.total_karma}
			/>
			<div
				style={{
					width: '90%',
					maxWidth: '400px',
					marginTop: '24px',
					position: 'sticky',
					zIndex: '50',
					top: '10px', // Adjust the top offset as needed
				}}>
				<BackButton onClick={navigate.bind(null, -1)} />
			</div>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					alignSelf: 'center',
					position: 'relative',
					marginTop: '16px',
				}}>
				{imageUrl ? (
					<LazyLoadImage src={imageUrl} alt='User' className={'image-banner'} />
				) : (
					<Spinner style={{ marginTop: '64px', color: COLORS.PRIMARY }} />
				)}
			</div>

			<div className={'upcoming-session-body-container'}>
				<span
					style={{
						color: '#1B1625',
						fontFamily: 'Gordita-Medium',
						fontSize: '20px',
						width: '100%',
						fontStyle: 'normal',
						alignSelf: 'start',
						fontWeight: 500,
						marginTop: '18px',
						lineHeight: '28px',
					}}>
					{calendarItem?.title}
				</span>
				<span
					style={{
						color: '#000',
						width: '100%',
						fontFamily: 'Gordita-Regular',
						fontSize: '14px',
						fontWeight: 400,
						alignSelf: 'start',
						marginTop: '6px',
						lineHeight: '20px',
					}}>
					{'By ' + calendarItem?.instructor}
				</span>
				<div className={'upcoming-session-subheader-container'}>
					<div className={'upcoming-session-subheader-subContainer'}>
						{calendarItem?.classType === 'ZOOM' ? (
							<ZoomDate style={{ marginRight: '4px' }} width={28} height={28} />
						) : (
							<TimeIcon style={{ marginRight: '4px' }} width={28} height={28} />
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '4px',
							}}>
							<span
								className={'upcoming-session-subheader-title'}
								style={{
									fontStyle: 'normal',
									fontWeight: '500',
									fontFamily: 'Gordita-Medium',
								}}>
								{calendarItem?.classType === 'ZOOM' ? zoomClassDate : '30 '}
								{calendarItem?.classType !== 'ZOOM' && (
									<span
										className={'upcoming-session-subheader-subtitle'}
										style={{ alignSelf: 'center', marginLeft: '2px' }}>
										{' Mins'}
									</span>
								)}
							</span>
							<span className={'upcoming-session-subheader-subtitle'}>
								{calendarItem?.classType === 'ZOOM' ? 'Date' : 'Duration'}
							</span>
						</div>
					</div>
					<div
						style={{ width: '1px', height: '40px', background: '#ECEFF2' }}
					/>
					<div className={'upcoming-session-subheader-subContainer'}>
						{(calendarItem?.classType === 'ZOOM' && (
							<WatchIcon
								style={{ marginRight: '4px' }}
								width={28}
								height={28}
							/>
						)) ||
							((calendarItem?.effort === 'Easy' ||
								calendarItem?.effort === 'LOW') && (
								<LowEffort
									style={{ marginRight: '4px' }}
									width={28}
									height={28}
								/>
							)) ||
							(calendarItem?.effort === 'Medium' && (
								<MediumEffort
									style={{ marginRight: '4px' }}
									width={28}
									height={28}
								/>
							)) ||
							((calendarItem?.effort === 'Hard' ||
								calendarItem?.effort === 'HIGH') && (
								<HighEffort
									style={{ marginRight: '4px' }}
									width={28}
									height={28}
								/>
							))}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '4px',
								alignSelf: 'start',
							}}>
							<span
								className={'upcoming-session-subheader-title'}
								style={{
									fontStyle: 'normal',
									fontWeight: '500',
									fontFamily: 'Gordita-Medium',
									alignSelf: 'start',
									marginLeft: '0px',
									justifyContent: 'start',
								}}>
								{calendarItem?.classType === 'ZOOM'
									? zoomClassTime
									: capitalizeFirstLetter(calendarItem?.effort)}
							</span>
							<span className={'upcoming-session-subheader-subtitle'}>
								{calendarItem?.classType === 'ZOOM' ? 'Time' : 'Intensity'}
							</span>
						</div>
					</div>
					<div
						style={{ width: '1px', height: '40px', background: '#ECEFF2' }}
					/>
					<div className={'upcoming-session-subheader-subContainer'}>
						{calendarItem?.classType === 'ZOOM' ? (
							<ZoomDuration
								style={{ marginRight: '4px' }}
								width={28}
								height={28}
							/>
						) : (
							<LanguageIcon
								style={{ marginRight: '4px' }}
								width={28}
								height={28}
							/>
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginLeft: '4px',
								alignSelf: 'start',
							}}>
							<span
								className={'upcoming-session-subheader-title'}
								style={{
									fontStyle: 'normal',
									fontWeight: '500',
									fontFamily: 'Gordita-Medium',
								}}>
								{calendarItem?.classType === 'ZOOM'
									? calendarItem?.duration + ' Mins'
									: calendarItem?.language || 'English'}
							</span>
							<span className={'upcoming-session-subheader-subtitle'}>
								{calendarItem?.classType === 'ZOOM' ? 'Duration' : 'Language'}
							</span>
						</div>
					</div>
				</div>
				{upcomingCourses
					?.filter((course: any) => {
						return (
							course.instructor === calendarItem.instructor &&
							!course.batch?.[0]?.user_already_enrolled
						);
					})
					.map((item: any, index: number) => {
						return index === 0 ? (
							<CourseNudge
								class_name={calendarItem?.title}
								onClick={onClick.bind(null, item.courseId, item?.title)}
								title={item?.title}
								subtitle={moment(
									new Date(
										item?.batch[0]?.start_date._seconds * 1000 +
											item?.batch[0]?.start_date._nanoseconds / 1000000
									)
								).format('DD MMM YY')}
								description={'Enrol Now'}
								thumbnailImageUrl={item?.thumbnail}
								instructor={calendarItem.instructor}
							/>
						) : (
							<></>
						);
					})}
				{specialInstruction && (
					<p
						style={{
							display: 'flex',
							width: '100%',
							padding: '6px 12px',
							fontStyle: 'italic',
							fontFamily: 'Gordita-Regular',
							fontWeight: 400,
							flexDirection: 'column',
							alignItems: 'flex-start',
							marginTop: '16px',
							fontSize: '12px',
							border: '1px solid #41CD70',
							background: '#DAFADD',
						}}>
						{specialInstruction}
					</p>
				)}
				<p
					style={{
						display: 'flex',
						fontFamily: 'Gordita-Regular',
						fontSize: '14px',
						fontWeight: '400',
						width: '100%',
						marginTop: specialInstruction ? '0px' : '12px',
						flexDirection: 'column',
						alignItems: 'flex-start',
					}}>
					{readMore ? (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignSelf: 'start',
								marginTop: '12px',
							}}>
							{parsedClassDescription?.length > 0 && (
								<p
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '14px',
										width: '344px',
										fontWeight: 400,
										marginTop: '-10px',
										lineHeight: '24px',
									}}>
									{parsedClassDescription?.[0]}
								</p>
							)}

							{parsedClassDescription?.length > 1 && (
								<p
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '14px',
										width: '344px',
										fontWeight: 400,
										marginTop: '-10px',
										lineHeight: '24px',
									}}>
									{parsedClassDescription?.[1]}
								</p>
							)}

							{parsedClassDescription?.length > 2 && (
								<p
									style={{
										color: '#2A2A2A',
										fontFamily: 'Gordita-Regular',
										fontSize: '14px',
										width: '344px',
										fontWeight: 400,
										marginTop: '-10px',
										lineHeight: '24px',
									}}>
									{parsedClassDescription?.[2]}
								</p>
							)}
						</div>
					) : (
						calendarItem?.description?.split(' ')?.slice(0, 25)?.join(' ') +
						'...'
					)}
				</p>
				{calendarItem?.description?.split(' ')?.length > 27 && (
					<div
						onClick={readMoreHandler}
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignSelf: 'start',
						}}>
						<span
							style={{
								display: 'flex',
								color: '#534DE0',
								textDecorationLine: 'underline',
								fontFamily: 'Gordita-Medium',
								fontWeight: 500,
								lineHeight: '16px',
								fontSize: '12px',
								flexDirection: 'column',
								alignItems: 'flex-start',
							}}>
							{readMore ? 'Read Less ' : 'Read More'}
						</span>
						{readMore ? (
							<svg
								style={{ alignSelf: 'center', marginTop: '-4px' }}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M10.0002 9.00003L13.4202 12.141C13.5503 12.2699 13.7261 12.3422 13.9092 12.3422C14.0923 12.3422 14.2681 12.2699 14.3982 12.141C14.4625 12.0778 14.5135 12.0023 14.5483 11.9192C14.5831 11.836 14.6011 11.7467 14.6011 11.6565C14.6011 11.5664 14.5831 11.4771 14.5483 11.3939C14.5135 11.3107 14.4625 11.2353 14.3982 11.172L10.4902 7.34203C10.3598 7.21336 10.1839 7.14121 10.0007 7.14121C9.81749 7.14121 9.64164 7.21336 9.51121 7.34203L5.60321 11.172C5.53896 11.2353 5.48794 11.3107 5.45311 11.3939C5.41828 11.4771 5.40035 11.5664 5.40035 11.6565C5.40035 11.7467 5.41828 11.836 5.45311 11.9192C5.48794 12.0023 5.53896 12.0778 5.60321 12.141C5.73335 12.2699 5.90908 12.3422 6.09221 12.3422C6.27534 12.3422 6.45108 12.2699 6.58121 12.141L10.0002 9.00003Z'
									fill='#534DE0'
								/>
							</svg>
						) : (
							<svg
								style={{ alignSelf: 'center', marginTop: '-4px' }}
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 20 20'
								fill='none'>
								<path
									d='M10.0002 11L13.4202 7.85897C13.5503 7.73012 13.7261 7.65784 13.9092 7.65784C14.0923 7.65784 14.2681 7.73012 14.3982 7.85897C14.4625 7.92224 14.5135 7.99766 14.5483 8.08084C14.5831 8.16402 14.6011 8.2533 14.6011 8.34347C14.6011 8.43365 14.5831 8.52292 14.5483 8.6061C14.5135 8.68928 14.4625 8.7647 14.3982 8.82797L10.4902 12.658C10.3598 12.7866 10.1839 12.8588 10.0007 12.8588C9.81749 12.8588 9.64164 12.7866 9.51121 12.658L5.60321 8.82797C5.53896 8.7647 5.48794 8.68928 5.45311 8.6061C5.41828 8.52292 5.40035 8.43365 5.40035 8.34347C5.40035 8.2533 5.41828 8.16402 5.45311 8.08084C5.48794 7.99766 5.53896 7.92224 5.60321 7.85897C5.73335 7.73012 5.90908 7.65784 6.09221 7.65784C6.27534 7.65784 6.45108 7.73012 6.58121 7.85897L10.0002 11Z'
									fill='#534DE0'
								/>
							</svg>
						)}
					</div>
				)}
				<div
					style={{
						marginTop: '24px',
						width: '100%',
					}}>
					{calendarItem?.highlights > 0 && (
						<span
							style={{
								color: '#4A4A4A',
								fontFamily: 'Gordita-Medium',
								fontSize: '20px',
								fontStyle: 'normal',
								fontWeight: 500,
								alignSelf: 'start',
								lineHeight: '28px',
								letterSpacing: '0.36px',
							}}>
							Highlights
						</span>
					)}

					{calendarItem?.highlights?.map(
						(item: { key: string }, index: number) => (
							<BestOutOfItIconWithName
								key={index}
								index={index}
								name={item?.key}
							/>
						)
					)}

					{calendarItem?.session_info?.map(
						(item: { key: string }, index: number) => (
							<SessionInfoIconWithName
								key={index}
								index={index}
								name={item.key}
							/>
						)
					)}
				</div>
				<div style={{ marginTop: '24px', width: '100%' }}>
					<span
						style={{
							color: '#4A4A4A',
							fontFamily: 'Gordita-Medium',
							fontSize: '20px',
							fontStyle: 'normal',
							fontWeight: 500,
							alignSelf: 'start',
							lineHeight: '28px',
							letterSpacing: '0.36px',
						}}>
						How to get the best of this class?
					</span>

					{bestOutOfIt
						?.filter((value) => value !== null)
						.map((value, index) => (
							<BestOutOfItIconWithName key={index} index={index} name={value} />
						))}
				</div>
				<span
					style={{
						color: '#4A4A4A',
						fontFamily: 'Gordita-Medium',
						fontSize: '20px',
						fontStyle: 'normal',
						fontWeight: 500,
						alignSelf: 'start',
						lineHeight: '28px',
						letterSpacing: '0.36px',
						marginTop: '24px',
					}}>
					Know your instructor
				</span>
				{!!calendarItem?.instructorData && (
					<KnowYourInstructor instructorData={calendarItem?.instructorData} />
				)}
				<div style={{ marginBottom: '84px' }} />
				<PrimaryButton
					className={`upcoming-join-liv-session-cta`}
					disabled={!isWithinStreamingWindow}
					isSticky={true}
					onClick={onJoinLiveSessionHandler}>
					{ctaTitle}
				</PrimaryButton>
			</div>
			<ToastContainer />
		</div>
	);
};

export default UpcomingSessions;
