import React, { useEffect, useState } from 'react';
import BottomSheet from '../../../components/model/BottomSheet';
import './PlanAddonBottomsheet.css';
import sleepBg from './../../../assets/webp-images/sleep-addon-bg.png';
import sleepCard from './../../../assets/webp-images/sleep-addon-card.png';
import whatYouGet from './../../../assets/webp-images/plan-addon-what-you-get.png';
import sleepCloudUp from './../../../assets/webp-images/sleep-addon-cloud-up.png';
import sleepCloudDown from './../../../assets/webp-images/sleep-addon-cloud-down.png';
import sleepCreative from './../../../assets/webp-images/sleep-addon-creative.png';
import planAddonNudge from './../../../assets/webp-images/plan-addon-nudge.png';
import planAddonSleepCreative from './../../../assets/webp-images/plan-addon-sleep-creative.png';
import planAddonCloud1 from './../../../assets/webp-images/plan-addon-cloud-1.png';
import planAddonCloud2 from './../../../assets/webp-images/plan-addon-cloud-2.png';
import reviewIcon from '../../../assets/star.svg';
import bullet from './../../../assets/new-icons/addon-bullet.svg';
import closeIcon from './../../../assets/new-icons/close-popup.svg';
import { STATES } from '../Plan';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css/autoplay';
import { FaqsCourse, groupByWeek } from '../../../utils/utils';
import CourseCurriculum from '../../course/components/CourseCurriculum';
import KnowYourInstructor from '../../instructor/KnowYourInstructor';
import FAQ from './FAQ';
import { Mixpanel } from '../../../analytics/Mixpanel';
import { events_page } from '../../../analytics/Events';

interface TriggeredEvents {
	[key: string]: boolean;
}

type Props = {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	onHide: () => void;
	addonAdded: boolean;
	state: STATES;
	setState: (value: STATES) => void;
	setAddonAdded: (value: boolean) => void;
	course: any;
};

const sectionRanges = {
	section1: {
		start: 0,
		end: 500,
		section_heading: 'Banner',
	},
	section2: {
		start: 300,
		end: 800,
		section_heading: 'Real stories, real impact',
	},
	section3: {
		start: 850,
		end: 1000,
		section_heading: 'Course Curriculum',
	},
	section4: {
		start: 1500,
		end: 1700,
		section_heading: 'Know your Instructors',
	},
	section5: { start: 2500, end: 1800, section_heading: 'FAQ and Support' },
} as { [key: string]: { start: number; end: number; section_heading: string } };

const PlanAddonBottomsheet: React.FC<Props> = ({
	isOpen,
	setIsOpen,
	onHide,
	addonAdded,
	state,
	setState,
	setAddonAdded,
	course,
}) => {
	const [timer, setTimer] = useState({ mins: 15, secs: 0 });
	const [courseCurriculum, setCourseCurriculum] = useState<any>();
	const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>();
	const [showMore, setShowMore] = useState(false);
	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
	});

	const onFAQClickHandler = (index: number) => {
		if (selectedFAQIndex !== index) {
			try {
				Mixpanel.track('click_plan_addon_landing_page_faqs', {
					event_action: 'clicked',
					faq_heading: course.FAQs[index].q,
					event_page: 'plan_landing_page',
				});
			} catch (e) {}
		}
		setSelectedFAQIndex((prevState) => (prevState === index ? null : index));
	};

	const onClickShowMoreReviews = () => {
		setShowMore(!showMore);
	};

	const markMixPanelEvent = (position: number) => {
		// console.log(position);
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (!triggeredEvents[sectionKey] && position < -start - 10) {
				Mixpanel.track('view_sleep_addon_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					event_page: 'plan_landing_page',
					course_name: course?.title,
				});
				setTriggeredEvents((prevEvents: any) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		if (!triggeredEvents['section1'] && course && isOpen) {
			Mixpanel.track('view_sleep_addon_section', {
				event_action: 'viewed',
				event_section: 'section1',
				section_heading: 'Choose your perfect plan!',
				event_page: 'plan_landing_page',
				course_name: course?.title,
			});
			setTriggeredEvents((prevEvents: any) => ({
				...prevEvents,
				section1: true,
			}));
		}
		const handleScroll = () => {
			markMixPanelEvent(
				document
					.getElementsByClassName('plan-addon-bottom-sheet-banner')[0]
					?.getBoundingClientRect().top!
			);
		};

		document
			.getElementById('plan-addon-bottom-sheet')
			?.addEventListener('scroll', handleScroll);
		return () => {
			document
				.getElementById('plan-addon-bottom-sheet')
				?.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents, course, isOpen]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (timer.secs === 0) {
				if (timer.mins === 0) {
					setTimer((prev) => ({ mins: 14, secs: 59 }));
				} else {
					setTimer((prev) => ({ mins: prev.mins - 1, secs: 59 }));
				}
			} else {
				setTimer((prev) => ({ mins: prev.mins, secs: prev.secs - 1 }));
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [timer]);

	useEffect(() => {}, [isOpen]);

	useEffect(() => {
		course?.batch[0]?.course_curriculum &&
			setCourseCurriculum(groupByWeek(course?.batch[0]?.course_curriculum));
	}, [course]);

	useEffect(() => {
		if (isOpen) {
			Mixpanel.track('view_sleep_addon', {
				event_action: 'viewed',
				event_page: 'plan_landing_page',
			});
		}
		if (!isOpen) {
			document
				.getElementsByClassName('plan-addon-bottom-sheet')[0]
				.scrollTo(0, 0);
		}
	}, [isOpen]);

	return (
		<BottomSheet
			show={isOpen}
			hideOnOverlayClick={false}
			onHide={onHide}
			customStyle={{
				height: '80vh',
				borderRadius: '27px 27px 0 0',
				overflow: 'hidden',
			}}
			contentCustomStyle={{
				padding: 0,
				borderRadius: '27px 27px 0 0',
				overflow: 'hidden',
			}}
			content={
				<div
					id='plan-addon-bottom-sheet'
					style={{
						paddingBottom: state === STATES.CHECKOUT_PAGE ? '24px' : '172px',
						overflowX: 'hidden',
					}}
					className='plan-addon-bottom-sheet'>
					<div className='plan-addon-bottom-sheet-banner'>
						<div className='plan-addon-bottom-sheet-banner-timer-wrapper'>
							<p className='plan-addon-bottom-sheet-banner-timer'>
								Offer expires in{' '}
								{`${String(timer.mins).padStart(2, '0')}:${String(
									timer.secs
								).padStart(2, '0')}s`}
							</p>
						</div>
						<p className='plan-addon-bottom-sheet-banner-header'>
							Add 3-day Sleep Course to my plan
						</p>
						<p className='plan-addon-bottom-sheet-banner-subtitle'>
							Improve your sleep to meditate 5x better
						</p>
						<div className='plan-addon-bottom-sheet-get-wrapper'>
							<img
								className='plan-addon-bottom-sheet-get-creative'
								src={planAddonSleepCreative}
								alt=''
							/>
							<img
								className='plan-addon-bottom-sheet-get-cloud-1'
								src={planAddonCloud1}
								alt=''
							/>
							<img
								className='plan-addon-bottom-sheet-get-cloud-2'
								src={planAddonCloud2}
								alt=''
							/>
							<div className='plan-addon-bottom-sheet-get'>
								<div className='plan-addon-bottom-sheet-get-item'>
									<div>
										<img src={bullet} alt='' />
									</div>
									<div>
										<p className='plan-addon-bottom-sheet-get-item-title'>
											3 classes every night
										</p>
										<p className='plan-addon-bottom-sheet-get-item-subtitle'>
											Class recordings available for 24 hours
										</p>
									</div>
								</div>
								<div
									style={{
										height: '1px',
										backgroundColor: '#f4f4f4',
										margin: '12px 0',
									}}></div>
								<div className='plan-addon-bottom-sheet-get-item'>
									<div>
										<img src={bullet} alt='' />
									</div>
									<div>
										<p className='plan-addon-bottom-sheet-get-item-title'>
											Yoga Nidra sleep library
										</p>
										<p className='plan-addon-bottom-sheet-get-item-subtitle'>
											Lifetime access of Yoga Nidra reset routine
										</p>
									</div>
								</div>
								<div
									style={{
										height: '1px',
										backgroundColor: '#f4f4f4',
										margin: '12px 0',
									}}></div>
								<div className='plan-addon-bottom-sheet-get-item'>
									<div>
										<img src={bullet} alt='' />
									</div>
									<div>
										<p className='plan-addon-bottom-sheet-get-item-title'>
											Holistic approach for deeper sleep
										</p>
										<p className='plan-addon-bottom-sheet-get-item-subtitle'>
											Ayurvedic diet, yoga and sleep science tips in each
											session
										</p>
									</div>
								</div>
								<div className='plan-addon-bottom-sheet-get-footer'>
									<p className='plan-addon-bottom-sheet-get-footer-text'>
										Get this course for
									</p>
									<p className='plan-addon-bottom-sheet-get-footer-price'>
										₹{course?.price / 2 - 1}
										<span>₹{course?.price}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className='plan-addon-bottom-sheet-testimonials'>
						<div className='plan-addon-bottom-sheet-testimonials-header'>
							<img
								className='plan-addon-bottom-sheet-testimonials-cloud-up'
								src={sleepCloudUp}
								alt=''
							/>
							<p className='plan-addon-bottom-sheet-testimonials-header-title'>
								Real stories, real impact
							</p>
							<div className='plan-addon-bottom-sheet-testimonials-header-rating-box'>
								<p className='plan-addon-bottom-sheet-testimonials-header-rating'>
									{course?.course_ratings?.rating}
								</p>
								<img src={reviewIcon} alt='' />
								<p className='plan-addon-bottom-sheet-testimonials-header-number'>
									({course?.course_ratings?.count})
								</p>
							</div>
							<img
								className='plan-addon-bottom-sheet-testimonials-cloud-down'
								src={sleepCloudDown}
								alt=''
							/>
						</div>

						<div>
							{course.testimonial_text
								.slice(0, showMore ? course.testimonial_text.length - 1 : 3)
								.map((testimonial: any, index: number) => {
									return (
										<div
											key={index}
											className='plan-addon-bottom-sheet-testimonials-slide'>
											<p className='plan-addon-bottom-sheet-testimonials-slide-content'>
												{testimonial.text}
											</p>
											<p className='plan-addon-bottom-sheet-testimonials-slide-name'>
												{testimonial.module}
											</p>
											<p className='plan-addon-bottom-sheet-testimonials-slide-exp'>
												{testimonial.name}
											</p>
										</div>
									);
								})}
							<div
								className={'horizontal-container'}
								style={{
									width: '100%',
									maxWidth: '375px',
									marginTop: '12px',
									display: 'flex',
									justifyContent: 'end',
								}}
								onClick={onClickShowMoreReviews}>
								<span
									style={{
										color: 'var(--purple-gradient, #000)',
										fontFamily: 'Gordita-Medium',
										fontSize: '12px',
										fontStyle: 'normal',
										fontWeight: 500,
										alignSelf: 'center',
										textDecorationLine: 'underline',
										lineHeight: '16px',
										pointerEvents: 'none',
									}}>
									{showMore ? 'Show less' : 'Show more'}
								</span>
								{showMore ? (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='17'
										viewBox='0 0 16 17'
										fill='none'>
										<path
											d='M12.5 10.7502L8 6.25024L3.5 10.7502'
											stroke='#1B1625'
											stroke-width='1.5'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
									</svg>
								) : (
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='17'
										viewBox='0 0 16 17'
										fill='none'>
										<path
											d='M12.5 6.25018L8 10.7502L3.5 6.25018'
											stroke='#1B1625'
											stroke-width='1.5'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
									</svg>
								)}
							</div>
						</div>
					</div>
					<div className='plan-addon-bottom-sheet-course'>
						<p className='plan-addon-bottom-sheet-title'>Course curriculum</p>
						{courseCurriculum && (
							<CourseCurriculum
								light={true}
								curriculum={courseCurriculum}></CourseCurriculum>
						)}
					</div>
					<div className='plan-addon-bottom-sheet-instructor'>
						<p className='plan-addon-bottom-sheet-title'>
							Know your Instructors
						</p>
						{course.instructorData ? (
							course.instructorData?.map((instructor: any, index: number) => {
								return (
									<>
										<KnowYourInstructor
											key={index}
											addon={true}
											instructorData={instructor}
										/>
										{index !== course?.instructorData?.length - 1 && (
											<div
												style={{
													height: '1px',
													borderBottom: '1px dashed rgb(214, 214, 214)',
													width: '100%',
													marginBottom: '12px',
												}}></div>
										)}
									</>
								);
							})
						) : (
							<></>
						)}
					</div>
					<div className='plan-addon-bottom-sheet-faqs'>
						<p className='plan-addon-bottom-sheet-title'>FAQs</p>
						{course.FAQs?.map((faq: any, index: any) => (
							<FAQ
								addon={true}
								selectedIndex={selectedFAQIndex}
								index={index}
								item={faq}
								onClickHandler={onFAQClickHandler}
							/>
						))}
					</div>
					{state !== STATES.CHECKOUT_PAGE && (
						<div
							className='plan-fixed-bottom-container'
							style={{
								paddingBottom: '12px',
								paddingTop: '16px',
								borderRadius: '0',
							}}>
							<img
								style={{
									position: 'absolute',
									top: '0',
									left: '0',
									width: '100%',
									transform: 'translateY(-100%)',
									filter: 'drop-shadow(0px -2px 2px  #00000015)',
								}}
								src={planAddonNudge}
								alt=''
							/>
							<button
								style={{ width: '100%' }}
								className='plan-fixed-bottom-button'
								onClick={() => {
									Mixpanel.track('click_sleep_addon_required', {
										event_action: 'viewed',
										event_page: 'plan_landing_page',
									});
									setAddonAdded(true);
									setState(STATES.CHECKOUT_PAGE);
									setIsOpen(false);
								}}>
								Yes, I want better sleep
							</button>
							<p
								style={{
									fontFamily: 'Gordita-Medium',
									color: '#4462EF',
									fontSize: '14px',
									textAlign: 'center',
									width: '100%',
									maxWidth: '375px',
									marginLeft: '12px',
									cursor: 'pointer',
									marginBottom: '8px',
									textDecoration: 'underline',
								}}
								onClick={() => {
									Mixpanel.track('click_sleep_addon_not_required', {
										event_action: 'viewed',
										event_page: 'plan_landing_page',
									});
									setAddonAdded(false);
									setState(STATES.CHECKOUT_PAGE);
									setIsOpen(false);
								}}>
								No, I don’t need help with sleep
							</p>
						</div>
					)}
					{state === STATES.CHECKOUT_PAGE && (
						<img
							onClick={() => setIsOpen(false)}
							style={{
								position: 'absolute',
								top: '16px',
								right: '16px',
								zIndex: 10,
							}}
							src={closeIcon}
							alt=''
						/>
					)}
				</div>
			}
		/>
	);
};

export default PlanAddonBottomsheet;
