import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DisableBackButtonOnRoute = ({ targetPath }: { targetPath: string }) => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === targetPath) {
			const handlePopState = (event: PopStateEvent) => {
				event.preventDefault();
				navigate(targetPath, { replace: true });
			};

			window.addEventListener('popstate', handlePopState);

			return () => {
				window.removeEventListener('popstate', handlePopState);
			};
		}
	}, [location.pathname, navigate, targetPath]);

	return null;
};

export default DisableBackButtonOnRoute;
