import React from 'react';
import './Loader.css'; // Make sure to import or define your styles

interface LoaderProps {
	style?: React.CSSProperties;
	grey?: boolean;
}

const Loader: React.FC<LoaderProps> = ({ style, grey }) => {
	const loaderContainerStyle: React.CSSProperties = {
		position: 'fixed',
		zIndex: 10,
		width: '64px',
		height: '32px',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)', // Center the loader horizontally and vertically
		...style, // Apply additional styles
	};

	return (
		<div className='loader-container' style={loaderContainerStyle}>
			<div
				style={{
					border: grey ? '8px solid #eeeeee' : '8px solid #ffffff',
					borderTop: '8px solid #1111ab',
				}}
				className='loader'></div>
		</div>
	);
};

export default Loader;
