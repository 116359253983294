import React, { useEffect, useState } from 'react';
import { ReactComponent as SeekLogo } from '../assets/seek-logo.svg';
import './login/Login.css';
import './LandingPageOne.css';
import { useLogin } from '../context/LoginContext';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../analytics/Mixpanel';
import { events_action } from '../analytics/Events';
import { Card } from 'react-bootstrap';
import akash_url from '../assets/webp-images/akash.webp';
import mindfulness_banner from '../assets/webp-images/mindfulness-baneer.webp';
import { ReactComponent as MindfulnessArrow } from '../assets/new-icons/mindfulness_arrow.svg';
import LandingTitleArrow from '../assets/webp-images/landing-title.png';
import { ReactComponent as LearnFromBest } from '../assets/new-icons/lear-from-best.svg';

import {
	HabitsCarouselItems,
	InstructionsCarouselItems,
	InstructorCarouselItems,
	LANDING_COMPONENT,
	LandingFaqs,
	MindfulnessCarouselItems,
	TestimonialCarouselItems,
} from '../utils/utils';
import FAQ from './plans/components/FAQ';

import { ReactComponent as AboutMetaData } from '../assets/about_data.svg';
import joinWhatsAppCommunityImage from '../assets/webp-images/whatsapp_chat.webp';
import landing_header from '../assets/webp-images/landing-header.svg';
import MidfullNessSlider from './MidfullNessSlider';
import TestimonialSlider from './TestimonialSlider';
import InstructionsSlider from './InstructionsSlider';
import InstructorSlider from './InstructorSlider';
import kshitij_url from '../assets/webp-images/Kshitij.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShimmerLoader from '../components-v2/shimmer/Shimmer';
import BottomContainer from '../components-v2/buttons/BottomContainer';
import TileGallery from '../components-v2/landing-page/TileGallery';
import OnBoarding from './signup-form/OnBoarding';
import { useSelector } from 'react-redux';

interface TriggeredEvents {
	[key: string]: boolean;
}

const sectionRanges = {
	// section1: {start: 0, end: 400, section_heading: 'Guided by the Best.'},
	section2: {
		start: 250,
		end: 450,
		section_heading: 'Make Mindfulness a Habit 25min Live Session.',
	},
	section3: {
		start: 450,
		end: 750,
		section_heading: 'Make Mindfulness a Habit Convenient Time Slot.',
	},
	section4: {
		start: 750,
		end: 1000,
		section_heading: 'Make Mindfulness a Choose your favourite class.',
	},
	section5: {
		start: 1200,
		end: 1600,
		section_heading: 'Mindful and Happier Everyday!',
	},
	section8: { start: 1700, end: 2200, section_heading: 'Useful Instructions.' },
	section6: { start: 2250, end: 2700, section_heading: 'Learn from the Best.' },
	section7: { start: 2900, end: 3200, section_heading: 'What do they think?' },
	section10: { start: 3200, end: 4000, section_heading: 'About Us.' },
	section11: { start: 4000, end: 4400, section_heading: 'FAQs.' },
	section12: { start: 4400, end: 4600, section_heading: 'Chat with us.' },
	// Add other sections as needed
} as { [key: string]: { start: number; end: number; section_heading: string } };

function LandingPageOne() {
	const navigate = useNavigate();
	const [readMore, setReadMore] = useState(false);

	const { login } = useLogin();
	const [selectedFAQIndex, setSelectedFAQIndex] = useState<number | null>();
	const [triggeredEvents, setTriggeredEvents] = useState<TriggeredEvents>({
		section1: false,
		section2: false,
		section3: false,
		section4: false,
		section5: false,
		section6: false,
		section7: false,
		section8: false,
		section9: false,
		section10: false,
		section11: false,
		section12: false,
	});

	const onboardingExperimentKey = useSelector(
		(state: any) => state.abExperiment.data.onboarding_experiment_key
	);

	const markMixPanelEvent = (position: number) => {
		const checkAndTriggerEvent = (
			sectionKey: string,
			start: number,
			end: number
		) => {
			if (
				!triggeredEvents[sectionKey] &&
				position >= start - 10 &&
				position <= end + 10
			) {
				Mixpanel.track('view_section', {
					event_action: 'viewed',
					event_section: sectionKey,
					section_heading: sectionRanges[sectionKey].section_heading,
					event_page: 'landing_page',
				});
				setTriggeredEvents((prevEvents) => ({
					...prevEvents,
					[sectionKey]: true,
				}));
			}
		};

		Object.entries(sectionRanges).forEach(([sectionKey, { start, end }]) => {
			checkAndTriggerEvent(sectionKey, start, end);
		});
	};

	useEffect(() => {
		const handleScroll = () => {
			// console.log("window.scrollY",window.scrollY);
			markMixPanelEvent(window.scrollY);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [triggeredEvents]);

	useEffect(() => {
		document.body.style.overflowX = 'hidden';
		window.addEventListener(
			'wheel',
			(e) => {
				if (e.deltaX !== 0) {
					e.preventDefault();
				}
			},
			{ passive: false }
		);

		window.addEventListener(
			'touchmove',
			(e) => {
				if (e.touches[0].clientX !== e.touches[0].screenX) {
					e.preventDefault();
				}
			},
			{ passive: false }
		);

		return () => {
			document.body.style.overflowX = 'initial';
			window.removeEventListener('touchmove', (e) => {
				if (e.touches[0].clientX !== e.touches[0].screenX) {
					e.preventDefault();
				}
			});
		};
	}, []);

	useEffect(() => {
		try {
			Mixpanel.track('view_section', {
				event_action: 'viewed',
				section_heading: 'Guided by the Best',
				event_page: 'landing_page',
			});
		} catch (e) {}
	}, []);

	const onFAQClickHandler = (index: number) => {
		try {
			Mixpanel.track('click_faqs_landing_page', {
				event_action: 'clicked',
				faq_question: LandingFaqs[index],
				event_page: 'landing_page',
				event_section: 'FAQs.',
			});
		} catch (e) {}
		setSelectedFAQIndex((prevState) => (prevState === index ? null : index));
	};

	const handleLogin = async () => {
		try {
			Mixpanel.track('click_login_landing_page', {
				event_page: 'landing_page',
				event_action: events_action.clicked,
			});
			navigate('/login');
		} catch (e) {}
	};

	const handleSignUp = async () => {
		try {
			Mixpanel.track('click_get_7_days_free', {
				event_page: 'landing_page',
				event_action: events_action.clicked,
				onboarding_experiment_key: onboardingExperimentKey,
			});
			// navigate('/signup')
			navigate('/onboarding');
		} catch (e) {}
	};

	const chatWhatsAppsHandler = async () => {
		try {
			Mixpanel.track('click_chat_with_us_landing_page', {
				event_action: 'clicked',
				event_page: 'landing_page',
			});
			const whatsappGroupLink = 'https://wa.me/9555434844';
			// window.localStorage.setItem('JoinedWhatsAppCommunity', 'true');
			window.open(whatsappGroupLink, '_blank');
		} catch (error) {
			console.error('Error:', error);
			// Handle the error as needed
		}
	};

	const readMoreAboutUS = async () => {
		if (!readMore) {
			// console.log("readMoreAboutUS event")
			Mixpanel.track('click_read_more_about_us', {
				event_page: 'landing_page',
				event_section: 'About Us.',
				event_action: events_action.clicked,
			});
		}
		setReadMore((prevState) => !prevState);
	};

	const onLinkedinClickHandlerFounderOne = () => {
		// console.log("onLinkedinClickHandlerFounderOne")
		const linkedInUrl = 'https://www.linkedin.com/in/akashjain91/';
		Mixpanel.track('click_founder_profile', {
			event_page: 'landing_page',
			founder_name: 'Akash',
		});
		// Open the LinkedIn profile URL in a new tab or the LinkedIn app if installed
		window.open(linkedInUrl, '_blank');
	};

	const onLinkedinClickHandlerFounderTwo = () => {
		// console.log("onLinkedinClickHandlerFounderTwo")
		const linkedInUrl = 'https://www.linkedin.com/in/kshitij-agarwal-5a2b5934/';
		// Open the LinkedIn profile URL in a new tab or the LinkedIn app if installed
		Mixpanel.track('click_founder_profile', {
			event_page: 'landing_page',
			founder_name: 'Kshitij',
		});
		window.open(linkedInUrl, '_blank');
	};

	return (
		<div className='login-container'>
			<Card
				style={{
					maxWidth: '400px',
					width: '100%',
					height: '56px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					borderBottom: '1px solid #E4E4E4',
					background: '#FFF',
					boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.08)',
					justifyContent: 'space-between',
					borderTop: 'none',
					borderLeft: 'none',
					borderRight: 'none',
				}}>
				<SeekLogo style={{ alignSelf: 'center', margin: '16px' }} />
				<p
					className={'login-cta'}
					onClick={handleLogin}
					style={{
						width: '40%',
						textAlign: 'right',
						margin: '16px',
						marginRight: '24px',
					}}>
					Login
				</p>
			</Card>
			<div className={'landing-header'}>
				<div className={'landing-header-title'}>
					{/* Your everyday partner for <span>meditation</span> & <span>yoga</span> */}
					<img src={LandingTitleArrow} alt='' />
				</div>
				{/* <div className={'landing-header-subtitle'}>
					The best meditation & yoga classes - expertly created for you.
				</div> */}
				<TileGallery />
			</div>
			<AboutMetaData
				style={{ position: 'relative' }}
				width={'80%'}
				height={'auto'}
			/>

			<div
				style={{
					position: 'relative',
					marginTop: '60px',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<span className={'landing-join-community-habit'}>
					Make Mindfulness a Habit
				</span>
				<MindfulnessArrow
					style={{ position: 'absolute', alignSelf: 'center', top: '14' }}
				/>
			</div>

			{HabitsCarouselItems.map((item, index) => (
				<div className='vertical-info-card' style={{ marginTop: '16px' }}>
					<div className='landing-card-title'>{item?.title}</div>
					<div className='landing-card-subtitle'>{item?.subtitle}</div>
					<img src={item?.imageUrl} alt='Card' className='card-image' />
				</div>
			))}

			<div
				style={{
					position: 'relative',
					width: 'auto',
					marginTop: '40px',
					height: 'auto',
					maxWidth: '400px',
					padding: '40px 24px',
					overflow: 'hidden',
					display: 'flex',
					alignItems: 'center',
					alignSelf: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}>
				<img
					loading='lazy'
					src={mindfulness_banner}
					style={{
						position: 'absolute',
						top: 0,
						width: '96%',
						height: '100%',
						alignSelf: 'center',
						objectFit: 'cover',
					}}
					alt='Background'
				/>
				<p
					style={{
						zIndex: 1,
						color: '#FFF',
						textAlign: 'center',
						fontFamily: 'Galaxie-Copernicus-Book',
						fontSize: '24px',
						width: '344px',
						// paddingTop: '40px 16px 0px 16px',
						fontStyle: 'normal',
						fontWeight: 500,
						lineHeight: '32px',
						letterSpacing: '0.32px',
					}}>
					Mindful and Happier everyday!
				</p>
				<div
					style={{
						width: '64px',
						height: '4px',
						zIndex: 1,
						marginTop: '24px',
						background: '#FFF',
						borderRadius: '1px',
					}}>
					{'.'}
				</div>
				<MidfullNessSlider
					style={{
						zIndex: 1,
						marginTop: '24px',
						width: '375px',
						height: '232px',
						alignSelf: 'center',
					}}
					naturalSlideHeight={264}
					naturalSlideWidth={280}
					component_name={LANDING_COMPONENT.MINDFULNESS}
					items={MindfulnessCarouselItems}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					alignSelf: 'center',
					alignItems: 'center',
				}}>
				<InstructionsSlider
					style={{
						width: '375px',
						height: '384px',
						marginTop: '40px',
						alignSelf: 'center',
					}}
					naturalSlideHeight={462}
					naturalSlideWidth={372}
					component_name={LANDING_COMPONENT.INSTRUCTIONS}
					items={InstructionsCarouselItems}
				/>
			</div>

			<div
				className={'landing-header'}
				style={{ position: 'relative', marginTop: '20px' }}>
				<LearnFromBest style={{ alignSelf: 'center', top: '14' }} />
				<InstructorSlider
					margin={'18px'}
					width={'320px'}
					style={{ width: '390px', height: '420px' }}
					naturalSlideHeight={420}
					naturalSlideWidth={390}
					component_name={LANDING_COMPONENT.INSTRUCTOR}
					items={InstructorCarouselItems}
				/>
			</div>

			<div
				className={'landing-header'}
				style={{ background: '#fff', marginTop: '-20px' }}>
				<span className={'landing-testimonials-title'}>
					What do they think?
				</span>
				<TestimonialSlider
					style={{
						width: '375px',
						height: '254px',
						marginTop: '0px',
						borderRadius: '12px',
					}}
					naturalSlideHeight={280}
					naturalSlideWidth={390}
					component_name={LANDING_COMPONENT.TESTIMONIAL}
					items={TestimonialCarouselItems}
				/>
			</div>
			<div style={{ marginTop: '-20px' }} className={'landing-header'}>
				<span className={'landing-testimonials-title'}>About Us</span>
				{readMore ? (
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span
							style={{
								color: '#3A3A3A',
								textAlign: 'center',
								fontFamily: 'Gordita-Regular',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: 400,
								lineHeight: '24px',
							}}>
							{
								'Kshitij and Akash are both from IIT and ISB. They left their cushy corporate jobs to pursue meditation and spreading this to the world in the right manner. Both have been long time practitioners of Vipassana and Kundalini. They travel the world to attract the best of the best teachers.'
							}
						</span>
						<span
							style={{
								color: '#3A3A3A',
								textAlign: 'center',
								fontFamily: 'Gordita-Regular',
								fontSize: '14px',
								fontStyle: 'normal',
								fontWeight: 400,
								marginTop: '12px',
								lineHeight: '24px',
							}}>
							{
								'Apart from this daily meditation offering, Seek also offers highly specialized courses on Kundalini, Vipassana, Chakra Meditation. They offer personalized mental fitness coaching and healing through various modalities mapped onto a more modern, secular and scientific world.'
							}
							<span
								onClick={readMoreAboutUS}
								style={{
									color: '#534DE0',
									fontFamily: 'Gordita-Medium',
									fontSize: '12px',
									fontStyle: 'normal',
									fontWeight: 500,
									textDecorationLine: 'underline',
								}}>
								{readMore ? 'READ LESS' : 'READ MORE'}
							</span>
						</span>
					</div>
				) : (
					<span
						style={{
							color: '#3A3A3A',
							textAlign: 'center',
							fontFamily: 'Gordita-Regular',
							fontSize: '14px',
							fontStyle: 'normal',
							fontWeight: 400,
							lineHeight: '24px',
						}}>
						{
							'Kshitij and Akash are both from IIT and ISB. They left their cushy corporate jobs to pursue meditation and spreading this to the world in the right manner. Both have been long time practitioners of Vipassana and Kundalini...'
						}
						<span
							onClick={readMoreAboutUS}
							style={{
								color: '#534DE0',
								fontFamily: 'Gordita-Medium',
								fontSize: '12px',
								fontStyle: 'normal',
								fontWeight: 500,
								textDecorationLine: 'underline',
							}}>
							{readMore ? 'READ LESS' : 'READ MORE'}
						</span>
					</span>
				)}

				<div
					style={{
						display: 'flex',
						position: 'relative',
						flexDirection: 'column',
					}}>
					<img
						loading='lazy'
						src={kshitij_url}
						alt=''
						style={{ width: '336px', height: '324px', objectFit: 'cover' }}
					/>

					<img
						loading='lazy'
						src={akash_url}
						alt=''
						style={{
							width: '336px',
							height: '324px',
							objectFit: 'cover',
							marginTop: '16px',
						}}
					/>
					<div
						onClick={onLinkedinClickHandlerFounderTwo}
						style={{
							background: 'transparent',
							position: 'absolute',
							right: '36px',
							top: '192px',
							height: '40px',
							width: '40px',
						}}
					/>
					<div
						onClick={onLinkedinClickHandlerFounderOne}
						style={{
							zIndex: 5,
							background: 'transparent',
							position: 'absolute',
							right: '36px',
							bottom: '92px',
							height: '40px',
							width: '40px',
						}}
					/>
				</div>
			</div>
			<p
				className={'landing-faq-title'}
				style={{
					alignSelf: 'start',
					width: '343px',
					margin: '20px auto 0px auto',
				}}>
				FAQs
			</p>
			{LandingFaqs?.map((faq, index) => (
				<FAQ
					page={'LANDING'}
					selectedIndex={selectedFAQIndex}
					index={index}
					item={faq}
					onClickHandler={onFAQClickHandler}
				/>
			))}
			<div
				style={{
					width: '90%',
					maxWidth: '375px',
					marginTop: '40px',
					position: 'relative',
				}}>
				<img
					src={joinWhatsAppCommunityImage}
					alt='Join WhatsApp Community Banner'
					onClick={chatWhatsAppsHandler}
					style={{
						width: '100%',
						height: 'auto',
						cursor: 'pointer',
						objectFit: 'cover',
					}}
				/>
			</div>
			<div style={{ marginBottom: '132px' }}></div>
			<BottomContainer
				onButtonClick={handleSignUp}
				text={'Become a part of 10K+ Seekers.'}
				label='Start Now'
			/>
		</div>
	);
}

export default LandingPageOne;
