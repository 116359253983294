import { useEffect, useRef, useState } from 'react';
import './UserProfileCard.css';

type Props = { data: any; index: number; animate: boolean };

const UserProfileCard: React.FC<Props> = ({ data, index, animate }) => {
	const [animation, setAnimation] = useState('');

	useEffect(() => {
		if (animate) {
			setAnimation(`borderFill${index + 1} 15s infinite`);
		}
	}, [animate, index]);
	return (
		<div
			style={{
				paddingTop: index === 0 ? 0 : '24px',
				paddingLeft: '24px',
				position: 'relative',
			}}>
			<div
				style={{
					position: 'absolute',
					left: 0,
					width: '4px',
					borderRadius: '50px',
					backgroundColor: '#d6d6d6',
					animation: animation,
				}}></div>
			<img
				style={{ width: '48.5px', marginBottom: '12px' }}
				src={data.icon}
				alt=''
			/>
			<p
				style={{
					fontFamily: 'Gordita-Medium',
					fontSize: '16px',
					color: '#4462EF',
					marginBottom: '0',
				}}>
				{data.title}
			</p>
			<p
				style={{
					fontFamily: 'Gordita-Regular',
					fontSize: '14px',
					color: '#4a4a4a',
				}}>
				{data.description}
			</p>
		</div>
	);
};

export default UserProfileCard;
