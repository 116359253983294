import React from 'react';
import planTestinmoialIcon from '../../assets/new-icons/plan-testimonial-icon.png';

import './NewTestimonialCard.css';

type Props = {
	data: { name: string; profession: string; text: string; subtext: string };
};

const NewTestimonialCard: React.FC<Props> = ({ data }) => {
	return (
		<div className='new-testimonial-card'>
			<img
				style={{ width: '41px', marginBottom: '16px' }}
				src={planTestinmoialIcon}
				alt=''
			/>
			<p
				style={{
					fontFamily: 'Gordita-Regular',
					fontSize: '20px',
					color: '#4a4a4a',
					marginBottom: '26px',
				}}>
				{data.text}
			</p>
			<div
				style={{
					display: 'flex',
					fontFamily: 'Gordita-Regular',
					fontSize: '12px',
					gap: '4px',
					color: '#1B1625',
				}}>
				<p style={{ fontFamily: 'Gordita-Medium', marginBottom: '0' }}>
					{data.name}
				</p>
				<p style={{ marginBottom: '0' }}>-</p>
				<p style={{ marginBottom: '0' }}>{data.profession}</p>
			</div>
			<p
				style={{
					fontFamily: 'Gordita-Regular',
					fontSize: '12px',
					color: '#4a4a4a',
					marginBottom: '0',
				}}>
				{data.subtext}
			</p>
		</div>
	);
};

export default NewTestimonialCard;
