import React, { useState, useEffect } from 'react';
import './TextAnimation.css'; // Import CSS file for styles

const TextAnimation = ({
	startAnimation,
	title,
	index,
	onAnimationComplete,
	style,
}: any) => {
	const [readIndexTitle, setReadIndexTitle] = useState(0);

	useEffect(() => {
		if (startAnimation) {
			const interval = setInterval(() => {
				setReadIndexTitle((prevIndex) => prevIndex + 1);
			}, 20); // Adjust the speed here (milliseconds per character)

			return () => clearInterval(interval);
		}
	}, [startAnimation]);

	useEffect(() => {
		if (readIndexTitle === title?.length) {
			onAnimationComplete(index);
		}
	}, [index, onAnimationComplete, readIndexTitle, title?.length]);

	return (
		<div
			style={{ marginTop: index === 2 ? '8px' : '0px' }}
			className='text-container'>
			<div className='text'>
				{title?.split('').map((char: any, index: number) => (
					<span
						key={index}
						className={
							index <= readIndexTitle ? 'animation-read' : 'animation-unread'
						}
						style={{ ...style }}>
						{char}
					</span>
				))}
			</div>
		</div>
	);
};

export default TextAnimation;
