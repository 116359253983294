import React, {useEffect, useMemo} from 'react';
import './App.css';
import './Calendar.css';
import './fonts/Lora-Medium.ttf';
import './fonts/Lora-Regular.ttf';
import './fonts/Lora-Italic.ttf';
import './fonts/Lora-SemiBold.ttf';
import './fonts/Poppins-Regular.ttf';
import './fonts/Poppins-Medium.ttf';
import './fonts/Poppins-SemiBold.ttf';
import './fonts/Poppins-Bold.ttf';
import './fonts/Poppins-Light.ttf';

import './fonts/Gordita-Black.otf';
import './fonts/Gordita-Bold.otf';
import './fonts/Gordita-Light.otf';
import './fonts/Gordita-Medium.otf';
import './fonts/Gordita-Regular.otf';
import './fonts/Galaxie-Copernicus-Bold.otf';
import './fonts/Galaxie-Copernicus-Book.ttf';
import 'bootstrap/dist/css/bootstrap.css';

import {LoginProvider} from './context/LoginContext';
import Landing from './screens/Landing';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setUtmDetails} from './store/features/UtmSlice';
import {useSelector} from 'react-redux';

function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    const utmDetails = useSelector((state: any) => state.utmDetails);


    useEffect(() => {
        console.log("useEffect app")
        const urlParams = new URLSearchParams(location.search);
        const utmParams = {
            utm_source: urlParams.get('utm_source'),
            utm_medium: urlParams.get('utm_medium'),
            utm_campaign: urlParams.get('utm_campaign'),
            utm_term: urlParams.get('utm_term'),
            utm_content: urlParams.get('utm_content'),
        };
        dispatch(setUtmDetails(utmParams));
    }, [dispatch, location.search]);

    return (
        <div className='App'>
            <LoginProvider>
                <Landing/>
            </LoginProvider>
        </div>
    );
}

export default App;
